import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  colorTheme?: string
  fontIcon?: string
  hasBullet?: boolean
  externalLink?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  colorTheme,
  fontIcon,
  hasBullet = false,
  externalLink,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div
      className={clsx(
        `menu-item bg-${colorTheme}-hover`,
        {'mb-2': hasBullet},
        {
          active: isActive,
          [`bg-${colorTheme}-active`]: isActive,
        }
      )}
    >
      {externalLink === true ? (
        <a
          className={clsx(`menu-link without-sub px-0 me-0`)}
          href={to}
          target='_blank'
          rel='noopener noreferrer'
        >
          {hasBullet ? (
            <span className='menu-bullet ms-9'>
              <span className={`bullet bullet-dot h-5px w-5px bg-custom-${colorTheme}`}></span>
            </span>
          ) : (
            <KTSVG
              path={`/media/company-specific/pokupka/frames/${colorTheme}.svg`}
              svgClassName='h-40px w-0'
            />
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTSVG path={icon} className='svg-icon-6' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span
            className={`menu-title ${isActive ? `text-custom-${colorTheme}` : 'text-custom-grey'}`}
          >
            {title}
          </span>
        </a>
      ) : (
        <Link
          className={clsx(`menu-link without-sub p-0 bg-${colorTheme}-hover me-0`)}
          to={to}
          state={pathname}
        >
          {hasBullet ? (
            <span className='menu-bullet ms-9'>
              <span className={`bullet bullet-dot h-5px w-5px bg-custom-${colorTheme}`}></span>
            </span>
          ) : (
            <KTSVG
              path={`/media/company-specific/pokupka/frames/${colorTheme}.svg`}
              svgClassName='h-40px w-0'
            />
          )}
          <div className='d-flex py-3'>
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                {' '}
                <KTSVG path={icon} className='svg-icon-6' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span
              className={`menu-title ${
                isActive ? `text-custom-${colorTheme}` : 'text-custom-grey'
              }`}
            >
              {title}
            </span>
          </div>
        </Link>
      )}
      {children}
    </div>
  )
}

export {SidebarMenuItem}
