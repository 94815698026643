/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {FormattedDate} from 'react-intl'
import {ICharge} from '../../../../core/_models'

type Props = {
  charge: ICharge
}

const DateCell: FC<Props> = ({charge}) => {
  return (
    <div className='d-flex '>
      <div className='d-flex flex-column fw-normal text-dark'>
        {charge.createdAt && (
          <FormattedDate year='2-digit' month='numeric' day='numeric' value={charge.createdAt} />
        )}
      </div>
    </div>
  )
}

export {DateCell}
