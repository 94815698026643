import clsx from 'clsx'
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {Row} from 'react-table'
import Details from './Details'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {OrderTypes} from '../../../../OrdersList'
import {TOneOfOrders} from '../../../../../../../../pages/orders/_models'
import {orderTypeColors} from '../../../../../goods/GoodsItemBody'

type Props = {
  row: Row<TOneOfOrders>
  orderType: OrderTypes
}

const CustomRow: FC<Props> = ({row, orderType}) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const [invoice, setInvoice] = useState({base_url: '', filename: ''})
  const [invoiceIsLoading, setInvoiceIsLoading] = useState(false)

  const saveImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceIsLoading(true)

    // const linkResponse = await handleFileUpload(
    //   e,
    //   setFieldValue,
    //   'passport',
    //   'file',
    //   receiversApi.addPassportScan
    // )

    // if (linkResponse) {
    //   setProdImage(linkResponse)
    // } else {
    //   // set error
    //   console.log('ошибка')
    // }
    setInvoiceIsLoading(false)
  }

  const deleteImage = () => {
    setInvoice({base_url: '', filename: ''})
  }
  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if ((event.target as HTMLInputElement).type === 'checkbox') {
      event.stopPropagation()
      return
    }
    setOpen(!open)
  }
  return (
    <>
      <tr
        {...row.getRowProps()}
        onClick={(e) => handleRowClick(e)}
        className={clsx({'pb-0 open': open})}
      >
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx(
                `h-70px h-md-50px py-2 border border-color-${orderTypeColors[orderType]}`,

                {
                  'text-center min-w-100px border-radius-right-def actions-td':
                    cell.column.id === 'actions',
                },
                {
                  'border-left-5 border-radius-left-def':
                    cell.column.id === 'date' && orderType !== 'buyout',
                },
                {
                  'border-left-5 border-radius-left-def':
                    cell.column.id === 'selection' && orderType === 'buyout',
                },
                {
                  'border-radius-left-bottom-unset border-radius-right-bottom-unset': open,
                },
                {'border-bottom-0 pb-0': open}
                // cell.column.getHeaderProps().className
              )}
            >
              <div className='d-flex align-items-center d-md-block'>
                {cell.render('Cell')}
                {!open && cell.column.id === 'number' && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className='d-md-none order-mob-row-arrow me-5 ms-3 svg-icon-custom-grey-light'
                    fontSize={11}
                  />
                )}
              </div>
            </td>
          )
        })}
      </tr>
      {open && <Details row={row} open={open} orderType={orderType} />}
      <tr className='h-0'>
        <th colSpan={8} className='h-0'></th>
      </tr>
    </>
  )
}

export {CustomRow}
