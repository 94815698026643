import {FormattedMessage} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

function CreateLoading() {
  return (
    <h1 className='fw-bolder text-dark mb-3'>
      <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
    </h1>
  )
}

export {CreateLoading}
