/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {ICharge} from '../../../../core/_models'

type Props = {
  charge: ICharge
}

const NumberCell: FC<Props> = ({charge}) => {
  return (
    <div className='d-flex align-items-center fw-normal text-dark'>
      <div className='d-flex flex-column'>{charge.order?.number}</div>
    </div>
  )
}

export {NumberCell}
