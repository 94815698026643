import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dropdown} from 'react-bootstrap'
import {DropDirection} from 'react-bootstrap/esm/DropdownContext'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

interface IProps {
  direction: DropDirection
  full: boolean
  floating: boolean
}

const AddButton = ({direction, full, floating}: IProps) => {
  // console.log('f', full, direction, floating)

  return (
    <Dropdown drop={direction}>
      <DropdownToggle
        className={
          full
            ? 'rounded-circle btn text-white fw-normal fw-bold btn-normal px-5 ms-2 btn-custom-text justify-content-center'
            : `btn-normal minimized-add-btn ${floating ? ' minimized-floating' : ''}`
        }
      >
        {full ? (
          <>
            <KTSVG path='/media/company-specific/pokupka/plus.svg' />
            <span className='menu__link-text'>
              <FormattedMessage id='ASIDE.CREATE' />
            </span>
          </>
        ) : (
          <FontAwesomeIcon icon={faPlus} fontSize={27} />
        )}
      </DropdownToggle>
      <DropdownMenu className='bg-primary-light' align={'end'}>
        <DropdownItem className='fs-4 text-custom-additional' as={Link} to='/orders/buyouts/new'>
          <FormattedMessage id='MENU.ORDER' />
        </DropdownItem>
        <DropdownItem className='fs-4 text-custom-accent' as={Link} to='/orders/tracks/new'>
          <FormattedMessage id='MENU.TRACK' />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AddButton
