import clsx from 'clsx'
import {ButtonGroup} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'

const HeaderNavigation = () => {
  const location = useLocation()

  const tab = location.pathname.split('/').at(-1)
  return (
    <ButtonGroup className='slider'>
      {' '}
      <Link
        to={'/finances/charges'}
        role='button'
        className={`btn btn-clean fs-6 ${clsx(
          {hover: tab === 'charges'},
          {
            'btn-shadow shadow-pokupka': tab === 'charges',
          }
        )}`}
      >
        <span className='text'>
          <FormattedMessage id='FINANCES.BTN_PAYMENTS' />
        </span>
      </Link>
      <Link
        to={'/finances/transactions'}
        role='button'
        className={`btn btn-clean fs-6 ${clsx(
          {hover: tab === 'transactions'},
          {
            'btn-shadow shadow-pokupka': tab === 'transactions',
          }
        )}`}
      >
        <span className='text'>
          <FormattedMessage id='FINANCES.BTN_HISTORY' />
        </span>
      </Link>
    </ButtonGroup>
  )
}

export default HeaderNavigation
