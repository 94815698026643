import {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout, usePageData} from '../../core'
import {DrawerComponent} from '../../../assets/ts/components'
import {WithChildren} from '../../../helpers'
import {paymentIntents} from '../../../../app/pages/finances/core/_payment-requests'

const Content = ({children}: WithChildren) => {
  const {config, classes} = useLayout()
  const {addNotification} = usePageData()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const appContentContainer = config.app?.content?.container

  const getPaymentStatus = async (sessionId: string) => {
    try {
      const res = await paymentIntents.checkPaymentStatus(sessionId)
      addNotification({
        status: res?.data?.status === 'complete' ? 'success' : 'warning',
        body:
          res?.data?.status === 'complete'
            ? 'GENERAL.TOOLTIPS.SUCCESSFUL_PAYMENT'
            : 'GENERAL.TOOLTIPS.FAILED_PAYMENT',
      })
    } catch (error) {
      console.log('payment error', error)
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const sessionId = queryParams.get('session_id')
    if (sessionId) {
      getPaymentStatus(sessionId)
    }
  }, [])
  return (
    <div
      id='kt_app_content'
      className={clsx(
        'app-content flex-column-fluid mb-16',
        classes.content.join(' '),
        config?.app?.content?.class
      )}
    >
      {appContentContainer ? (
        <div
          id='kt_app_content_container'
          className={clsx(
            'app-container',
            classes.contentContainer.join(' '),
            {
              'container-xxl': appContentContainer === 'fixed',
              'container-fluid': appContentContainer === 'fluid',
            },
            'px-0 border border-1 rounded border-transparent'
          )}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export {Content}
