import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {getConfig} from '../../../i18n/Metronici18n'
import {CategoriesQueryResponse, ICategory} from '../tsHelpers/categoriesHelpers'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const BASE_URL = `${API_URL}/product_categories`

const getCategories = (query: string): Promise<CategoriesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(
      `${BASE_URL}?${query}`,

      {
        headers: {
          'Accept-Language': selectedLang,
        },
      }
    )
    .then((d: AxiosResponse<CategoriesQueryResponse>) => {
      console.log(d)
      const transformed = {...d.data, data: d.data.data?.reverse()}
      return transformed
    })
}

const getCategoryById = (id: ID): Promise<ICategory | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(
      `${BASE_URL}/${id}`,

      {
        headers: {
          'Accept-Language': selectedLang,
        },
      }
    )
    .then((response: AxiosResponse<ICategory>) => response.data)
}

export {getCategories, getCategoryById}
