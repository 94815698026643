import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {useLayout, usePageData} from '../../../../../_metronic/layout/core'
import {useAuth} from '../../../../modules/auth'
import {getTOS} from '../../../../modules/auth/core/_requests'
import {crauzerProfileApi} from '../core/_requests'

interface Props {
  registerProps?: {
    isOpen: boolean
    setIsOpen: (b: boolean) => void
  }
}
const TermsOfServiceModal = ({registerProps}: Props) => {
  const [tos, setTOS] = useState('')
  const {selectedLang} = getConfig()

  const {pageCustomData, setPageCustomData} = usePageData()

  const {setCurrentUser, currentUser} = useAuth()
  const {websiteData} = useLayout()
  useEffect(() => {
    document.body.classList.add('modal-open')

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    if (websiteData?.id) {
      getTOS(websiteData.id).then((response) => {
        setTOS(response.data)
      })
    }
  }, [websiteData.id])

  const handleClose = () =>
    registerProps
      ? registerProps.setIsOpen(false)
      : setPageCustomData((prev: any) => ({...prev, tosShow: false}))

  const acceptTos = async () => {
    try {
      const response = await crauzerProfileApi.editProfile({
        tosConfirmed: true,
      })
      console.log('resp', response)

      if (response) {
        setCurrentUser({...currentUser, ...response})
        handleClose()
      }
    } catch (error) {
      console.log('err tos', error)
    }
  }

  const closeOverlay = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (currentUser?.tosConfirmed || registerProps) {
      if (e.currentTarget === e.target) {
        handleClose()
      }
    } else {
      return
    }
  }

  return (
    <>
      {(pageCustomData?.tosShow || registerProps?.isOpen) && Object.entries(tos).length > 0 && (
        <>
          <div
            className={`modal fade show d-block`}
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
            onClick={closeOverlay}
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered modal-xl'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <div className='modal-header'>
                  {/* begin::Modal title */}
                  <h4 className='fw-bolder'>
                    <FormattedMessage id='PROFILE.PAGE.OVERVIEW.TERMS_OF_SERVICE' />
                  </h4>
                  {/* end::Modal title */}

                  {/* begin::Close */}
                  {(currentUser?.tosConfirmed || registerProps) && (
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-users-modal-action='close'
                      onClick={() => handleClose()}
                      style={{cursor: 'pointer'}}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                  )}
                  {/* end::Close */}
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7 text-center'>
                  <div
                    className='TermsOfServiceModal_text-container'
                    dangerouslySetInnerHTML={{__html: tos}}
                  ></div>{' '}
                  {currentUser?.tosConfirmed || registerProps ? (
                    <></>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-lg btn-primary'
                      onClick={() => {
                        acceptTos()
                      }}
                    >
                      <FormattedMessage id='GENERAL.BUTTONS.ACCEPT' />
                    </button>
                  )}
                </div>

                {/* end::Modal body */}
              </div>

              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {TermsOfServiceModal}
