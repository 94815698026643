import clsx from 'clsx'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG, QUERIES, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useQuery} from 'react-query'
import {getCurrBalance} from '../../../../app/pages/balance/core/_requests'
import {useEffect} from 'react'
import {HeaderUserCreds} from '../../../partials/layout/header-menus/HeaderUserCreds'
import {Languages} from '../../../../app/modules/auth/components/Languages'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'

const Navbar = () => {
  const {currentUser, updateBalance} = useAuth()
  // const {data, isFetched} = useQuery(`${QUERIES.BALANCE_CURRENT}`, getCurrBalance, {
  //   cacheTime: 0,
  //   keepPreviousData: true,
  //   refetchOnWindowFocus: true,
  // })

  // useEffect(() => {
  //   if (isFetched === true) {
  //     updateBalance(data)
  //   }
  // }, [data, isFetched])

  return (
    <div className='app-navbar flex-shrink-0 align-items-center ms-2'>
      <Languages chosenLangStyle='fs-5' arrowStyle='w-10px h-10px' />

      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      {/* <div className={clsx('app-navbar-item BalanceComponent', itemClass)}>
        <Link to={'/balance'} className='fs-6 text-hover-primary '>
          <span className='me-1 text-dark'>
            <FormattedMessage id='HEADER_USER_MENU.BALANCE' />
          </span>
          <span
            style={{
              color:
                (currentUser?.balance && +currentUser.balance >= 0) || currentUser?.balance === null
                  ? 'green'
                  : 'red',
            }}
          >
            0
             {currentUser?.balance || 0} 
          </span>
        </Link>
      </div> */}

      <div className={clsx('app-navbar-item d-none d-md-block', itemClass)}>
        <HeaderUserCreds />{' '}
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <KTSVG
          path={'/media/company-specific/pokupka/cart.svg'}
          className='svg-icon-2 svg-icon-custom-additional me-2'
        />{' '}
        <span className='text-custom-accent bg-light-dark px-2 fs-6 fw-bolder d-none d-md-block'>
          0
        </span>
      </div>
      <div className={clsx('app-navbar-item d-none d-md-block', itemClass)}>
        <KTSVG path={'/media/company-specific/pokupka/help.svg'} className='svg-icon-2 me-2' />{' '}
      </div>
      <div className={clsx('app-navbar-item d-none d-md-block', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol me-3', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTSVG path={'/media/company-specific/pokupka/profile.svg'} className='svg-icon-2' />
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
