import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {ID, selectDebounce} from '../../../../../_metronic/helpers'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'

import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ICrauzerAddressCities} from '../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'

import {InputTemplate} from '../InputTemplate'

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const CitiesSelect = ({withTitle = true, inputName = 'city'}) => {
  const intl = useIntl()
  const {values, setFieldValue, dirty, initialValues} = useFormikContext<Record<string, any>>()

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<(IBasicSelect & Partial<ICrauzerAddressCities>)[]>([])

  const filterCities = useCallback(
    selectDebounce((query: string, callback?: any) => {
      setResultsLoading(true)

      locationsApi
        .getAddressCities(query)
        .then((res) => {
          if (res) {
            setResults(res)

            if (callback) {
              callback(res)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [locationsApi.getAddressCities]
  )

  useEffect(() => {
    filterCities('')
  }, [filterCities])

  return (
    <InputTemplate
      inputName={inputName}
      required={true}
      type='select'
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.CitySelect.LABEL'}) : ''}
      objectErrorKey={`${inputName}.label`}
      reactSelectprops={{
        currentValue: values[inputName],
        options: results,
        selectName: inputName,
        isLoading: resultsLoading,
        onInputChange: filterCities,
        setFieldValue: setFieldValue,
        setObject: true,
        placeholder: intl.formatMessage({
          id: 'COMPONENTS.CitySelect.PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'COMPONENTS.CitySelect.NO_MESSAGE',
        }),
      }}
    />
  )
}

export default CitiesSelect
