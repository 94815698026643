import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {getSavedPagination, initialQueryState, KTCard, KTSVG} from '../../../../_metronic/helpers'
import {ReceiversTable} from './table/ReceiversTable'
import {ReceiversListHeader} from './components/header/ReceiversListHeader'
import {FormattedMessage} from 'react-intl'
import {useEffect} from 'react'
import {Link} from 'react-router-dom'

const ReceiversList = () => {
  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()

    updateState({
      ...initialQueryState,
      itemsPerPage: retrievedData,
    })
  }, [])

  return (
    <KTCard>
      <ReceiversListHeader />

      <ReceiversTable />
    </KTCard>
  )
}

const ReceiversListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ReceiversList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ReceiversListWrapper}
