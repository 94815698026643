import {faCreditCard} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {establishHostFromUrl} from '../../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {usePageData} from '../../../../../../_metronic/layout/core'
import {useListView} from '../../../core/ListViewProvider'
import {paymentIntents} from '../../../core/_payment-requests'

const TransactionsListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const [loading, setLoading] = useState(false)
  const {setPageCustomData, pageCustomData} = usePageData()

  // const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.BUYOUTS_LIST}-${query}`])
  //     clearSelected()
  //   },
  // })

  const requestOrderPaymentDetails = async () => {
    try {
      setLoading(true)
      const host = establishHostFromUrl()
      const res = await paymentIntents.sendOrdersAndChargesPaymentIntent(
        [],
        selected.map((i) => i.id),
        `${
          host === 'localhost' ? 'http://localhost:3011' : `https://my.${host.replace('www.', '')}`
        }/orders/buyouts?session_id={CHECKOUT_SESSION_ID}`
      )
      console.log('payment intent response', res)

      if (res?.clientSecret) {
        setPageCustomData({...pageCustomData, clientSecret: res?.clientSecret})
      }
    } catch (error) {
      console.log('payment intent error', error)
    } finally {
      setLoading(false)
    }
  }

  console.log('sel', selected)

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-normal me-5'>
        {/* <button
          type='button'
          className='d-flex btn btn-clean btn-shadow shadow-pokupka px-4 text-custom-accent fw-bold with-icon'
          onClick={requestOrderPaymentDetails}
        >
          {!loading ? (
            `${selected.length}/${
              selected.find((i) => i.data?.currency)?.data?.currency
              // @ts-ignore
            } ${selected.reduce(
              // @ts-ignore

              (acc, i) => acc + +i?.data?.amount,
              0
            )}`
          ) : (
            <span className='indicator-progress' style={{display: 'block'}}>
           

              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button> */}
      </div>

      {/* <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button> */}
    </div>
  )
}

export {TransactionsListGrouping}
