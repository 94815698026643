import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../i18n/Metronici18n'
import {directionsFuncs} from '../funcs/directions'
import {establishOwnerFromDomain} from '../funcs/establishOwnerFromDomain'
import {Direction, DirectionsQueryResponse} from '../tsHelpers/directionsHelpers'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API = process.env.REACT_APP_API_PATH

const ENDPOINT_BASE = `/directions`

const fetchDirectionsWithIds = (countries: Record<string, string> | undefined) => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${API}/public${ENDPOINT_BASE}?enabled=true`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<Direction[]>) => {
      return d.data.map((item) => ({
        ...item,
        label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries || {}),
        value: item.id,
      }))
    })
}

export const directionsApi = {
  fetchDirectionsWithIds,
}
