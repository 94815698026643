import {Navigate, Routes, Route, Outlet, useLocation, useParams} from 'react-router-dom'

import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {useIntl} from 'react-intl'
import {ID, QUERIES} from '../../../../../_metronic/helpers'
import {getOrderById} from '../../_requests'
import {IOrder} from '../../_models'
import {CreateBuyout} from '../_modals/CreateBuyout'
import {transformDirectionsForForm} from '../../../../modules/custom/sections/orders/OrdersList'
import {Direction} from '../../../../../_metronic/helpers/custom/tsHelpers/directionsHelpers'

interface Props {}

const BuyoutPage = ({}: Props) => {
  const {id} = useParams()
  const {data: buyoutById} = useQuery(`buyout-${id}`, () => {
    return getOrderById(id)
  })

  const intl = useIntl()
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const directions: Direction[] | undefined = queryClient.getQueryData([QUERIES.DIRECTIONS_LIST])

  const [currBuyout, setCurrBuyout] = useState<IOrder | null>(null)

  useEffect(() => {
    if (buyoutById) {
      setCurrBuyout({...buyoutById})
    }
  }, [buyoutById])

  return (
    <>
      {currBuyout !== null ? (
        <>
          {currBuyout && buyoutById && directions && (
            <CreateBuyout directions={transformDirectionsForForm(directions, countries)} />
          )}
        </>
      ) : (
        intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})
      )}
    </>
  )
}

export default BuyoutPage
