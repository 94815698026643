import {useState} from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {FormattedMessage, useIntl} from 'react-intl'
import {
  ISetFieldValue,
  TFooterNotification,
} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {addReceiverSchema} from '../core/yup/receiver-page'
import {addReceiverAddress, createReceiver} from '../core/_requests'
import {IReceiverToSend, NewReceiverFullForm} from '../core/_models'
import {CountriesQueryResponse} from '../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'
import {KTCard, QUERIES} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {IReceiverShipmentAddressToSend} from '../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {CardFooter} from '../../../modules/custom/form-elements/CardFooter'
import {ProfileNumberItem} from '../../profile/pokupka/components/contacts/ProfileNumberItem'
import Name from './elements/Name'
import ShipmentDetails from './elements/ShipmentDetails'
import Country from './elements/Country'
import DelTypeRadios from './elements/DelTypeRadios'
import CitiesSelect from '../../../modules/custom/form-elements/select/CitiesSelect'
import {ReceiverType} from './elements/ReceiverType'
import InternationalPhysAddress from './elements/InternationalPhysAddress'
import {ReceiverTypeCheckbox} from './elements/ReceiverTypeCheckbox'
import {Notification} from '../../../modules/custom/Notification'
import {handleFileUpload} from '../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {FileInput} from '../../../modules/custom/form-elements/file-inputs/FileInput'
import {receiversApi} from '../../../../_metronic/helpers/custom/api/receiversApi'

const CreateReceiver = () => {
  const intl = useIntl()

  const {refetch, response} = useQueryResponse()
  const [isLoading, setIsLoading] = useState(false)
  const [createResponse, setCreateResponse] = useState('')
  const [addressValues, setAddressValues] = useState<any>(null)
  const [newReceiverId, setNewReceiverId] = useState('')
  const [imageIsLoading, setImageIsLoading] = useState(false)
  const [currPassport, setCurrPassport] = useState({documentUrl: '', document: ''})

  const {currentUser} = useAuth()

  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const countriesData: CountriesQueryResponse =
    queryClient.getQueryData(QUERIES.COUNTRIES_LIST) || {}
  const [footerNotification, setFooterNotification] = useState<TFooterNotification>(null)
  console.log('cd', countriesData)

  // inits
  const defaultDataForTheFirstReceiver =
    response?.data?.length === 0
      ? {
          firstName: currentUser?.firstName || '',
          lastName: currentUser?.lastName || '',
          patronymic: currentUser?.patronymic || '',
          phoneNumber: currentUser?.phoneNumber || '',
          email: currentUser?.email || '',
        }
      : {
          firstName: '',
          lastName: '',
          patronymic: '',
          phoneNumber: '',
          email: '',
        }

  const inits: NewReceiverFullForm = {
    ...defaultDataForTheFirstReceiver,
    countryConfig: {
      ...countriesData?.data?.[0],
      value: countriesData?.data?.[0]?.id || '',
      label: countriesData?.data?.[0]?.country ? countries[countriesData?.data?.[0]?.country] : '',
    },
    premisesType: 'apartment',
    physicalAddressPremisesType: 'apartment',
    noPatronymic: false,
    document: currPassport.documentUrl,
    receiverType: response?.data?.length === 0 ? [1, 2] : [1],
    typeOfDelivery: 1,
    city: '',
    commonReceiver: false,
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const createReceiverMutation = useMutation(createReceiver, {
    onMutate: () => {
      setCreateResponse('loading')
    },
    onSuccess: (data) => {
      setCreateResponse('success')

      // setNewReceiverId(data.id || '')
    },

    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)

      cancel(true)
    },
  })

  const addReceiverAddressMutation = useMutation(addReceiverAddress, {
    onSuccess: () => {
      setCreateResponse('success')
    },
    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      cancel(true)
      setIsLoading(false)
    },
  })

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue?: ISetFieldValue
  ) => {
    try {
      if (setFieldValue) {
        setIsLoading(true)
        setImageIsLoading(true)

        const linkResponse = await handleFileUpload(
          e,
          setFieldValue,
          'document',
          'document',
          receiversApi.addPassportScan
        )

        setCurrPassport(linkResponse)
      }
    } catch (error) {
      // set error
      console.log('ошибка')
    } finally {
      setImageIsLoading(false)
      setIsLoading(false)
    }
  }

  const deleteImage = () => {
    setCurrPassport({documentUrl: '', document: ''})
  }

  const handleSubmit = (values: NewReceiverFullForm, actions: FormikValues) => {
    console.log('values', values)
    try {
      setIsLoading(true)

      const {
        firstName,
        lastName,
        commonReceiver,
        patronymic,
        phoneNumber,
        receiverType,
        noPatronymic,
        countryConfig,
      } = values
      const toSend: Partial<IReceiverToSend> & Partial<IReceiverShipmentAddressToSend> = {
        firstName,
        lastName,
        noPatronymic: patronymic ? false : true,
        document: currPassport.document,
        documentUrl: currPassport.documentUrl,
        patronymic,
        common: commonReceiver,
        phoneNumber: phoneNumber,
        international: receiverType.includes(1) ? true : false,
        final: receiverType.includes(2) ? true : false,
        withinCountry: receiverType.includes(3) ? true : false,
        country: countryConfig.value,
      }

      // setSubmittedValues(toSend)
      // setSubmittedForm(true)
      createReceiverMutation.mutate(toSend)
      // transfer to mutation success
      actions.resetForm()
      setCurrPassport({documentUrl: '', document: ''})
    } catch (error) {}
  }

  // useEffect(() => {
  // shapeAddressData(submittedValues)

  //   if (submittedForm && addressValues) {
  //     addReceiverAddressMutation.mutate({...addressValues, receiver_id: newReceiverId})
  //   }
  // }, [addressValues, newReceiverId])

  return (
    <KTCard className='list-card'>
      <div className='card-header border-0 shadow-pokupka p-5 align-items-center'>
        <h3 className='fw-normal text-custom-additional'>
          <FormattedMessage id='RECEIVERS.PAGE.LIST.ADD_BUTTON' />
        </h3>
      </div>
      <div className='card-body card-scroll create-order-height px-10 pt-6'>
        <Formik
          validationSchema={addReceiverSchema(intl)}
          initialValues={inits}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({values, setFieldValue, errors, setValues, isValid}) => (
            <Form noValidate className='form'>
              {/* {JSON.stringify(errors)}*/}
              <div className='row'>
                <div className='col col-md-12 d-flex flex-row flex-lg-nowrap flex-gutter justify-content-between'>
                  <Name values={values} />
                  <InputTemplate
                    inputName='phoneNumber'
                    required={true}
                    fieldWidth='w-100 w-md-50 w-lg-25'
                    title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PHONE_LABEL'})}
                    type='tel'
                    setFieldValue={setFieldValue}
                    value={values.phoneNumber}
                    custom={<ProfileNumberItem noError={true} />}
                  />
                  <Country values={values} />
                </div>
                <div className='w-100 w-md-50'>
                  <FileInput
                    inputName='document'
                    title='Копія паспорта'
                    inputId={`add-receiver`}
                    uploadedFile={{
                      base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                      link: currPassport.documentUrl,
                    }}
                    setFieldValue={setFieldValue}
                    uploadFunction={saveImage}
                    deleteFunction={deleteImage}
                    required={true}
                    loading={imageIsLoading}
                    linkEndpoint='/passport' // suggested name, no endpoint for now
                  />
                </div>
                <div className='col col-md-12 mb-4'>
                  <div>
                    <ReceiverTypeCheckbox
                      key={1}
                      chosenTypes={values.receiverType || []}
                      setFieldValue={setFieldValue}
                      title={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER',
                      })}
                      typeValue={1}
                      noteText={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER.DESC',
                      })}
                      disabled={response?.data?.length === 0}
                    />

                    {values.receiverType.includes(1) && (
                      <div className='mt-1 mb-3'>
                        <span className={`p-1 fs-7 fw-semobold bg-light StatusBar__status mx-5`}>
                          <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.INTERN_ADDRESS_NOTIF' />
                        </span>
                      </div>
                    )}
                  </div>

                  {values.receiverType.includes(1) && <InternationalPhysAddress />}
                </div>
                <div className='col col-md-12'>
                  <div>
                    <ReceiverTypeCheckbox
                      key={2}
                      chosenTypes={values.receiverType || []}
                      setFieldValue={setFieldValue}
                      title={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL',
                      })}
                      typeValue={2}
                      noteText={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL.DESC',
                      })}
                      disabled={response?.data?.length === 0}
                    />
                    {values.receiverType.includes(1) && (
                      <div className='mt-1 mb-3'>
                        <span className={`p-1 fs-7 fw-semobold bg-light StatusBar__status mx-5`}>
                          <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.FINAL_ADDRESS_NOTIF' />
                        </span>
                      </div>
                    )}
                  </div>

                  {values.receiverType.includes(2) && (
                    <>
                      <div className='d-flex flex-gutter'>
                        <div className='w-100 w-md-50'>
                          <CitiesSelect />
                        </div>
                        {values.city && (
                          <div className='col-6'>
                            <DelTypeRadios />
                          </div>
                        )}
                      </div>
                      {values?.city && <ShipmentDetails values={values} />}{' '}
                    </>
                  )}
                </div>
              </div>

              <CardFooter
                addClass='shadow-pokupka justify-content-end px-8'
                buttonClass='d-flex btn btn-clean btn-shadow shadow-pokupka px-2 text-custom-accent fw-bold with-icon'
                loading={isLoading}
                success={footerNotification?.success || false}
                notification={footerNotification}
                addButton={true}
                addButtonSvg='/media/company-specific/pokupka/plus.svg'
                buttonText={intl.formatMessage({id: 'GENERAL.BUTTONS.ADD'}, {item: ''})}
              />
            </Form>
          )}
        </Formik>
      </div>
    </KTCard>
  )
}

export {CreateReceiver}
