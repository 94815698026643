import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../core/QueryResponseProvider'
import {chargesColumns} from './columns/_columns'
import {ChargesListLoading} from '../components/loading/ChargesListLoading'
import {ChargesListPagination} from '../components/pagination/ChargesListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {ICharge} from '../../core/_models'

const ChargesTable = () => {
  const receivers = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => receivers, [receivers])
  const columns = useMemo(() => chargesColumns, [])
  const intl = useIntl()
  const {response: receiversTotal} = useQueryResponse()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-7 px-4 px-md-9'>
      <div className='d-none d-md-block'>
        {receiversTotal?.totalItems !== undefined && receiversTotal?.itemsPerPage !== undefined && (
          <ChargesListPagination
            showPagination={receiversTotal?.totalItems > receiversTotal?.itemsPerPage}
            showItemsPerPage={receiversTotal?.totalItems <= 10 ? false : true}
          />
        )}
      </div>
      <div className='table-responsive mb-4'>
        <table
          id='kt_table_receivers'
          className='table table-orders table-half-rounded position-relative align-middle fs-6 no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-muted fs-6 gs-0'>
              {headers.map((column: ColumnInstance<ICharge>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ICharge>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id='CHARGES.PAGE.LIST.NO_RESULTS' />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {receiversTotal?.totalItems !== undefined && receiversTotal?.itemsPerPage !== undefined && (
        <ChargesListPagination
          showPagination={receiversTotal?.totalItems > receiversTotal?.itemsPerPage}
          showItemsPerPage={receiversTotal?.totalItems <= 10 ? false : true}
          addClass='mt-4'
        />
      )}
      {isLoading && <ChargesListLoading />}
    </KTCardBody>
  )
}

export {ChargesTable}
