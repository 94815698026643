/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Formik, Form, FormikValues, FormikHelpers} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {createBuyoutSchema} from '../core/yup/schemas'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {CreateLoading} from '../../../../modules/custom/responseHandlers/CreateLoading'
import {CreateError} from '../../../../modules/custom/responseHandlers/CreateError'
import {useIntl} from 'react-intl'
import {useListView} from '../core/ListViewProvider'
import {Goods} from '../../../../modules/custom/sections/goods/Goods'
import {reformTrackFormProductsToApi} from '../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {Direction} from '../../../../../_metronic/helpers/custom/tsHelpers/directionsHelpers'
import {
  IBasicSelect,
  TFooterNotification,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {addOrder, editOrder} from '../../_requests'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {AddBuyoutForm} from '../core/_buyout-models'
import {ICreateNewOrder, IOrder} from '../../_models'
import {usePageData} from '../../../../../_metronic/layout/core'
import {ID, QUERIES} from '../../../../../_metronic/helpers'
import {establishHostFromUrl} from '../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {paymentIntents} from '../../../finances/core/_payment-requests'
import {useParams} from 'react-router-dom'
import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'

type TProps = {directions: (IBasicSelect & Partial<Direction>)[]}

const CreateBuyout = ({directions}: TProps) => {
  const {id} = useParams()
  const listView = useListView()
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const {setPageCustomData, pageCustomData} = usePageData()
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const buyoutToEdit: IOrder | undefined = queryClient.getQueryData([`buyout-${id}`])
  console.log('toed', buyoutToEdit)
  const invalidateBuyout = () => {
    queryClient.invalidateQueries(`buyout-${id}`)
  }

  useEffect(() => {
    listView.setItemIdForUpdate(null)
    setPageCustomData({checkoutButton: !buyoutToEdit})
  }, [])

  const goodsFields = buyoutToEdit
    ? buyoutToEdit.products.map((i) => ({
        category: {
          label: i.title,
          value: i.category.id ? +i.category.id : i.category.id,
          logoUrl: i.category.logoUrl,
        },
        qty: i.quantity,
        cost: i.priceForOne,
        total: i.priceForAll,
        url: i.url || '',
        sku: i.sku || '',
        color: i.color || '',
        size: i.size || '',
      }))
    : [
        {
          category: {label: '', value: ''},
          qty: 1,
          cost: '',
          total: '',
          url: '',
          sku: '',
          color: '',
          size: '',
        },
      ]

  const inits: AddBuyoutForm = {
    direction: buyoutToEdit
      ? {
          value: buyoutToEdit.direction?.id,
          label: directionsFuncs.formDirection(
            buyoutToEdit.direction?.countryFrom || '',
            buyoutToEdit.direction?.countryTo || '',
            countries
          ),
        }
      : directions[0],
    prods: goodsFields,
    store: buyoutToEdit ? buyoutToEdit.websiteShop : undefined,
  }

  const [footerNotification, setFooterNotification] = useState<TFooterNotification>(null)

  const [createResponse, setCreateResponse] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [orderId, setOrderId] = useState<null | ID>(null)
  const createBuyoutMutation = useMutation(addOrder, {
    onMutate: () => {
      setIsLoading(true)
      setCreateResponse('loading')
    },
    onSuccess: (data) => {
      setCreateResponse('success')
      console.log('cr buyout d', data)

      if (pageCustomData?.checkoutShow && pageCustomData?.isSubmitted) {
        console.log('oidb', orderId)

        setOrderId(data?.id)
        console.log('oida', orderId)
      }

      // else {
      // listView.setItemIdForUpdate(undefined)
      // }
    },

    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const editBuyoutMutation = useMutation(editOrder, {
    onMutate: () => {
      setIsLoading(true)
      setCreateResponse('loading')
    },
    onSuccess: (data) => {
      setCreateResponse('success')
      console.log('cr buyout d', data)
      invalidateBuyout()

      //  if (pageCustomData?.checkoutShow && pageCustomData?.isSubmitted) {
      //    console.log('oidb', orderId)

      //    setOrderId(data?.id)
      //    console.log('oida', orderId)
      //  }

      // else {
      // listView.setItemIdForUpdate(undefined)
      // }
    },

    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const requestOrderPaymentDetails = async () => {
    try {
      const host = establishHostFromUrl()

      const res = await paymentIntents.sendOrdersAndChargesPaymentIntent(
        [orderId],
        [],
        `${
          host === 'localhost' ? 'http://localhost:3011' : `https://my.${host.replace('www.', '')}`
        }/orders/buyouts?session_id={CHECKOUT_SESSION_ID}`
      )
      console.log('payment intent response', res)

      if (res?.clientSecret) {
        // window.open(res.path, '_blank')
        setPageCustomData({...pageCustomData, ...res})
      }
    } catch (error) {
      console.log('payment intent error', error)
    }
  }

  useEffect(() => {
    if (orderId) {
      requestOrderPaymentDetails()
    }
  }, [orderId])

  const handleSubmit = async (values: AddBuyoutForm, actions: FormikValues) => {
    console.log('values', values)
    setPageCustomData({...pageCustomData, isSubmitted: true})

    const {direction, prods, store} = values
    const products = await reformTrackFormProductsToApi(prods)
    const buyoutData: Pick<ICreateNewOrder, 'direction' | 'products' | 'type' | 'websiteShop'> = {
      direction: direction.value ? {id: direction.value} : null,
      // @ts-ignore
      products,
      type: 'buyout',
      websiteShop: {id: store?.id},
    }

    console.log('buyoutDataToSend', buyoutData)
    if (buyoutToEdit) {
      editBuyoutMutation.mutate({id: buyoutToEdit.id, ...buyoutData})
    } else {
      createBuyoutMutation.mutate(buyoutData)
      actions.resetForm()
    }
  }

  return (
    <div className='card-body card-scroll create-order-height px-10 pt-6'>
      {createResponse === 'loading' && <CreateLoading />}
      {createResponse === 'error' && <CreateError />}
      {createResponse !== 'error' && createResponse !== 'loading' && (
        <Formik
          validationSchema={createBuyoutSchema(intl)}
          initialValues={inits}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({values, setFieldValue, errors}) => (
            <Form className='form' noValidate id='kt_modal_create_buyout_form'>
              {/* {JSON.stringify(values.direction?.minBuyoutPrice)} */}

              <Goods
                prods={values.prods}
                setFieldValue={setFieldValue}
                values={values}
                listView={listView}
                setFooterNotification={setFooterNotification}
                orderType='buyout'
              />
              <CardFooter
                addClass='shadow-pokupka justify-content-end px-8'
                buttonClass={
                  buyoutToEdit
                    ? ''
                    : 'd-flex btn btn-clean btn-shadow shadow-pokupka px-2 text-custom-accent fw-bold with-icon'
                }
                loading={isLoading}
                success={footerNotification?.success || false}
                notification={footerNotification}
                addButton={buyoutToEdit ? false : true}
                buttonText={
                  buyoutToEdit ? undefined : intl.formatMessage({id: 'BUYOUTS.LIST.ADD_BUTTON'})
                }
                disabled={
                  values.direction?.minBuyoutPrice
                    ? values.prods.reduce((acc, i) => acc + +i.total, 0) <
                      values.direction?.minBuyoutPrice
                    : false
                }
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export {CreateBuyout}
