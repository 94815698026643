import {AddTrackForm} from '../../../../pages/orders/tracks/core/_tracks-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

import {useFormikContext} from 'formik'
import {ListViewContextProps} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import {InputTemplate} from '../../form-elements/InputTemplate'
import {getStoreByProductUrl} from '../../../../pages/orders/_requests'
import {getCategories} from '../../../../../_metronic/helpers/custom/api/categoriesApi'
import {handleFileUpload} from '../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {FileInput} from '../../form-elements/file-inputs/FileInput'
import DirectionsSelect from '../../form-elements/select/DirectionsSelect'
import {IFormProd} from '../../../../pages/orders/_prod-models'
import {IStore} from '../../../../../_metronic/helpers/custom/tsHelpers/storeModels'
import {CategoriesWrapper} from '../categories-crauzer/CategoriesWrapper'

interface Props {
  item: IFormProd
  index: number
  arrayHelpers: any
  prods: IFormProd[]
  setFieldValue: ISetFieldValue
  values: {prods: IFormProd[]; store?: IStore}
  listView: ListViewContextProps
}

export const orderTypeColors = {buyout: 'additional', track: 'accent', parcel: 'grey-light'}

export function GoodsItemBody({
  item,
  index,
  arrayHelpers,
  prods,
  setFieldValue,
  values,
  listView,
}: Props) {
  const intl = useIntl()
  const {itemIdForUpdate, orderType} = listView
  const [prodImage, setProdImage] = useState({base_url: '', filename: ''})

  const [urlError, setUrlError] = useState('')
  const {errors} = useFormikContext<AddTrackForm>()
  const findStore = async (url: string) => {
    try {
      const res = await getStoreByProductUrl(url)

      if (res?.id && values.store?.id) {
        if (+values.store?.id !== +res?.id) {
          setUrlError(intl.formatMessage({id: 'BUYOUT.VALIDATION.SINGLE_SHOP'}))
        } else {
          setUrlError('')
        }
      } else if (res?.id && !values.store) {
        setFieldValue('store', res)
        setUrlError('')
      } else {
        setUrlError('')
      }

      console.log('6pm', res)
    } catch (error) {
      if (values.store?.id) {
        setUrlError(intl.formatMessage({id: 'BUYOUT.VALIDATION.SINGLE_SHOP'}))
      } else {
        setUrlError('')
      }

      console.log(error)
    }
  }
  const [imageIsLoading, setImageIsLoading] = useState(false)

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue?: ISetFieldValue
  ) => {
    setImageIsLoading(true)

    // const linkResponse = await handleFileUpload(
    //   e,
    //   setFieldValue,
    //   'passport',
    //   'file',
    //   receiversApi.addPassportScan
    // )

    // if (linkResponse) {
    //   setProdImage(linkResponse)
    // } else {
    //   // set error
    //   console.log('ошибка')
    // }
    setImageIsLoading(false)
  }

  const deleteImage = () => {
    setProdImage({base_url: '', filename: ''})
  }

  return (
    <div
      className={`p-6 mb-4 border border-radius-def border-color-${
        orderType ? orderTypeColors[orderType] : ''
      } row`}
    >
      <div className='col-md-6 col-lg-6'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='mb-2'>
              <DirectionsSelect />
            </div>
            <div className='col'>
              {' '}
              <InputTemplate
                inputName={`prods.${index}.url`}
                disabled={!!itemIdForUpdate}
                type='text'
                required={true}
                title={intl.formatMessage({id: 'BUYOUTS.FORM.GOODS_LINK'})}
                value={item.url}
                marginClass='mb-2'
                handleChange={(e: string) => {
                  console.log('e', e)

                  const cleaned = e.replace(/\s/g, '')
                  setFieldValue(`prods.${index}.url`, cleaned)
                  findStore(cleaned)
                }}
                prependedFieldImage={
                  values.store && (
                    <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer position-absolute top-50 translate-middle-y ps-3'>
                      <img
                        alt=''
                        className='rounded-circle w-35px h-35px me-2 fit-content'
                        src={
                          `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${values.store?.logoUrl}` ||
                          '/media/icons/duotune/ecommerce/ecm002.svg'
                        }
                      />
                    </div>
                  )
                }
              />
              <div className='text-danger'>{urlError}</div>
            </div>{' '}
            <CategoriesWrapper
              inputName={{
                category: `prods.${index}.category`,
                user_name: `prods.${index}.user_name`,
                model: `prods.${index}.model`,
              }}
              setFieldValue={setFieldValue}
              selectValues={{
                category: item.category || {value: '', label: ''},
              }}
              apiRequest={getCategories}
              disabled={!!itemIdForUpdate}
              values={item}
            />
          </div>
          <div className='col-md-4'>
            <InputTemplate
              inputName={`prods.${index}.color`}
              type='text'
              disabled={!!itemIdForUpdate}
              title={intl.formatMessage({id: 'COLOR'})}
              // value={item.color}
            />
            <InputTemplate
              inputName={`prods.${index}.size`}
              type='text'
              disabled={!!itemIdForUpdate}
              title={intl.formatMessage({id: 'SIZE'})}
              // value={item.size}
            />
            <InputTemplate
              inputName={`prods.${index}.cost`}
              type='number'
              required={true}
              disabled={!!itemIdForUpdate}
              title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST'})}
              addSymbol={'$'} // change later to the direction currency
              value={item.cost}
              handleChange={(val: number) => {
                //  if for item.qty till full api
                if (item.qty) {
                  setFieldValue(`prods.${index}.total`, (val * +item.qty).toFixed())
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className='col-md-6 col-lg-6'>
        <div className='row'>
          <div className='col-md-5 col-lg-4'>
            <InputTemplate
              inputName={`prods.${index}.sku`}
              type='text'
              disabled={!!itemIdForUpdate}
              title={intl.formatMessage({id: 'BUYOUT.SKU'})}
              // value={item.sku}
            />
            <InputTemplate
              inputName={`prods.${index}.qty`}
              disabled={!!itemIdForUpdate}
              type='number'
              required={true}
              title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.QTY'})}
              value={item.qty}
              handleChange={(val: number) => {
                //  if for item.cost till full api
                if (item.cost) {
                  setFieldValue(`prods.${index}.total`, (val * +item.cost).toFixed())
                }
              }}
              numberWithControls={true}
            />
            <InputTemplate
              inputName={`prods.${index}.total`}
              addSymbol={'$'} // change later to the direction currency
              type='number'
              required={true}
              disabled={!!itemIdForUpdate}
              title={intl.formatMessage({id: 'SUM'})}
              value={item.total}
              handleChange={(val: number) => {
                if (item.qty) {
                  setFieldValue(`prods.${index}.cost`, (val / +item.qty).toFixed(1))
                }
              }}
            />
          </div>{' '}
          <div className='col-md-5 col-lg-8'>
            <div className='h-75 bg-gray-200 mb-3 border-radius-def d-none d-md-block'></div>
            {/* <div className='h-75'>
              <FileInput
                inputName='image'
                title='Інвойс'
                inputId={`add-order-${index}-prod-form`}
                uploadedFile={{
                  base: prodImage?.base_url || '',
                  link: prodImage?.filename || '',
                }}
                setFieldValue={setFieldValue}
                uploadFunction={saveImage}
                deleteFunction={deleteImage}
                required={true}
                loading={imageIsLoading}
                linkEndpoint='/buyout' // suggested name, no endpoint for now
              />
            </div> */}
            {index === 0 && (
              <button
                className='btn btn-clean w-100 d-flex align-items-center btn-shadow shadow-pokupka justify-content-center'
                type='button'
                onClick={() => {
                  const newItem: IFormProd = {
                    category: {label: '', value: ''},
                    qty: 1,
                    cost: '',
                    total: '',
                    url: '',
                  }
                  newItem.sku = ''
                  newItem.color = ''
                  newItem.size = ''
                  arrayHelpers.unshift(newItem)
                }}
              >
                <FormattedMessage id='ORDERS.ADD_PROD' />
              </button>
            )}
            {prods.length > 1 && index > 0 && !itemIdForUpdate && (
              <button
                className='btn btn-clean w-100 d-flex align-items-center btn-shadow shadow-pokupka justify-content-center'
                type='button'
                onClick={() => arrayHelpers.remove(index)}
              >
                <FormattedMessage id='GENERAL.BUTTONS.DELETE' />
              </button>
            )}
          </div>{' '}
        </div>
      </div>
    </div>
  )
}
