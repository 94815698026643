import {ID, QUERIES} from '../../../_metronic/helpers'
import {useQuery, useQueryClient} from 'react-query'
import {directionsFuncs} from '../../../_metronic/helpers/custom/funcs/directions'
import FlagsDirectionItem from './FlagsDirectionItem'
import {directionsApi} from '../../../_metronic/helpers/custom/api/directionsApi'
import {Direction} from '../../../_metronic/helpers/custom/tsHelpers/directionsHelpers'

interface Props {
  chosenDirections: {id: ID}[]
  additionalClass?: string
  minified?: boolean
}

function FlagsDirectionBar({chosenDirections, additionalClass, minified = false}: Props) {
  const queryClient = useQueryClient()

  const directions: Direction[] = queryClient.getQueryData([QUERIES.DIRECTIONS_LIST]) || []

  return (
    <ul
      className={`FlagsDirectionBar justify-content-center ${additionalClass || ''} ${
        minified === true ? 'px-0 mb-0' : 'me-2'
      }`}
    >
      {' '}
      {directions &&
        directionsFuncs
          .parseAvailableDirections(directions, chosenDirections)
          .map((item, index) => (
            <FlagsDirectionItem key={index} from={item.from} to={item.to} minified={minified} />
          ))}
    </ul>
  )
}

export {FlagsDirectionBar}
