import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {
  ID,
  initialQueryState,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../../../../_metronic/helpers'
import {locationsApi} from '../../../../../../../../../_metronic/helpers/custom/api/locationsApi'
import {objectToArray} from '../../../../../../../../../_metronic/helpers/custom/funcs/objectToArray'
import {IBasicSelect} from '../../../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ICountry} from '../../../../../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'
import {useQueryRequest} from '../../../../../../../../pages/orders/buyouts/core/QueryRequestProvider'
import {useQueryResponsePagination} from '../../../../../../../../pages/orders/buyouts/core/QueryResponseProvider'
import {CountriesSelect} from '../../../../../../form-elements/select/CountriesSelect'
import DirectionFilter from './DirectionFilter'

const Filter = () => {
  const pagination = useQueryResponsePagination()
  const {updateState, state} = useQueryRequest()
  const [savedFilter, setSavedFilter] = useState<Record<string, any>>({})
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const resetFilter = () => {
    updateState({
      filter: undefined,
      ...initialQueryState,
      itemsPerPage: pagination.itemsPerPage || 10,
    })
    setSavedFilter({})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.ORDERS_LIST), '{}')
  }

  const filterData = () => {
    const newFilters = {
      direction: savedFilter?.directions?.value,
    }

    updateState({
      ...state,
      filter: {...state.filter, ...newFilters},
    })
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.ORDERS_LIST),
      JSON.stringify(newFilters)
    )
  }

  useEffect(() => {
    filterData()
  }, [savedFilter?.directions])

  // const [countriesLoading, setCountriesLoading] = useState(false)
  // const [countriesResults, setCountriesResults] = useState<(IBasicSelect & Partial<ICountry>)[]>([])
  // const filterCountries = async () => {
  //   setCountriesLoading(true)
  //   await locationsApi
  //     .getAvailableCountries('filter[send]=true&filter[receive]=false')
  //     .then((res) => {
  //       console.log(res?.data)

  //       if (res?.data)
  //         setCountriesResults(
  //           res?.data?.map((i) => ({...i, value: i.id, label: countries[i.country]}))
  //         )
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setCountriesLoading(false))
  // }
  // useEffect(() => {
  //   filterCountries()
  // }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-end'>
        <DirectionFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter} />
        {/* <CountriesSelect
          setCountriesLoading={setCountriesLoading}
          setCountriesResults={setCountriesResults}
          options={countriesResults}
          isLoading={countriesLoading}
          onInputChange={filterCountries}
          onChange={(e: any) => setCountry(e.value)}
          addContClass='w-100 mt-0'
          placeholder='ORDERS.FILTER_COUNTRY'
        /> */}
      </div>
    </>
  )
}

export {Filter}
