import {FormattedMessage} from 'react-intl'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {
  Invoice,
  Logs,
  Parcel,
  Statuses,
} from '../../../modules/custom/sections/orders/list/table/modules'
import {getParcelById} from '../core/_requests'
import Content from './Content'
import Header from './Header'
import ShortInfo from './ShortInfo'

const ParcelPage = () => {
  const {id} = useParams()
  const {data: parcelById} = useQuery(`parcel-${id}`, () => {
    return getParcelById(id)
  })
  return (
    <>
      {parcelById && (
        <KTCard className='page-card h-100'>
          <Header parcel={parcelById} />

          <KTCardBody className='py-0 row h-100'>
            <div className='col col-md-7 py-7 px-4 ps-md-8 pe-md-6 py-md-9 px-lg-7 py-lg-10 h-100 parcel-page-left-side'>
              {/* <Invoice order={parcelById} /> */}
              <ShortInfo parcel={parcelById} />
              <span className='separator my-5' />
              <div className='mb-4 mb-md-5'>
                <Parcel order={parcelById} orderType='parcel' pageView={true} />
              </div>
              <div className='mb-md-4'>
                <Content parcel={parcelById} />
              </div>
            </div>
            <span className='separator d-md-none my-5' />
            <div className='col col-md-5 py-7 px-4 py-md-9 ps-md-5 pe-md-7 px-lg-7 py-lg-10 details'>
              <p className='text-custom-additional fs-3 mb-10'>
                <FormattedMessage id='PARCEL.PAGE.TRACKING_LABEL' />
              </p>{' '}
              <div className='px-3 mb-12'>
                <Statuses order={parcelById} withTitle={false} />
              </div>
              <Logs order={parcelById} full={true} />
            </div>
          </KTCardBody>
        </KTCard>
      )}
    </>
  )
}

export default ParcelPage
