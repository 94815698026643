import {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ImageLightbox} from '../images/ImageLightbox'

type TProps = {
  itemNumber: string
  files?: string[]
  defaultUrl: string
  tooltip?: string
}

export const CellImage = ({itemNumber, files, defaultUrl, tooltip}: TProps) => {
  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div className='symbol symbol-30px d-flex justify-content-center'>
      {files && files.length > 0 ? (
        <div className='cursor-zoom-in'>
          <div className='symbol symbol-fixed symbol-30px position-relative'>
            <img
              src={files[0]}
              width='30'
              height='30'
              alt='order'
              onClick={openLightbox}
              style={{objectFit: 'contain'}}
            />

            <ImageLightbox
              viewerIsOpen={viewerIsOpen}
              closeLightbox={closeLightbox}
              currentImage={currentImage}
              images={files.map((item, index) => {
                return {src: item, title: `${itemNumber}-${index + 1}`}
              })}
            />
          </div>
        </div>
      ) : tooltip ? (
        <OverlayTrigger placement='left' overlay={<Tooltip>{tooltip}</Tooltip>}>
          <img src={toAbsoluteUrl(defaultUrl)} alt='order' />
        </OverlayTrigger>
      ) : (
        <img src={toAbsoluteUrl(defaultUrl)} alt='order' />
      )}
    </div>
  )
}
