import {useFormikContext} from 'formik'
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES, selectDebounce} from '../../../../../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../../../../../_metronic/helpers/custom/funcs/directions'
import {IBasicSelect} from '../../../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FlagsDirectionBar} from '../../../../../../../../modules/custom/FlagsDirectionBar'
import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import {directionsApi} from '../../../../../../../../../_metronic/helpers/custom/api/directionsApi'
import {customStyles} from '../../../../../../form-elements/select/ReactSelectTemplate'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}

const DirectionFilter = ({savedFilter, setSavedFilter}: TProps) => {
  const intl = useIntl()

  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterDirections = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      directionsApi
        .fetchDirectionsWithIds(countries)
        .then((res) => {
          if (res) {
            const reformed = res?.map((item) => {
              return {
                ...item,
                label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries),
                value: item.id,
              }
            })
            setResults(reformed)
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [directionsApi.fetchDirectionsWithIds]
  )

  useEffect(() => {
    filterDirections()
  }, [])

  const optionFormat = (v: IBasicSelect) => (
    <div className='tagify__dropdown__item d-flex align-items-center h-40px' tabIndex={0}>
      <FlagsDirectionBar chosenDirections={[{id: v.value}]} minified={true} />

      <div className='d-flex flex-column fw-normal ms-2'>{v.label}</div>
    </div>
  )
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Не знайдено</span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      callback(results)
      return
    }

    filterDirections('')
  }

  // const formik = useFormikContext<any>()

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    console.log('selectedValue', selectedValue)
    setSavedFilter((prev) => {
      return {...prev, directions: selectedValue}
    })
    // if (onChange) {
    //   onChange(selectedValue)
    //   return
    // } else if (formik && selectedValue !== null) {
    //   formik?.setFieldValue('user', selectedValue)
    // } else {
    //   formik?.setFieldValue('user', '')
    // }
  }

  return (
    <div
      className={`fv-row d-flex mt-1
      flex-column mb-4 w-250px`}
    >
      <AsyncSelect
        styles={{
          ...customStyles,
          valueContainer: (provided) => ({...provided, height: '40px', paddingRight: '0'}),
          control: (provided) => {
            return {...provided, minHeight: '40px', height: '40px', borderRadius: '0'}
          },
        }}
        formatOptionLabel={optionFormat}
        className='form-control form-control-lg border-0 p-0 fw-normal fs-6'
        loadOptions={loadOptions}
        defaultOptions={results}
        form={'directions'}
        // closeMenuOnSelect={false}
        isDisabled={false}
        components={{NoOptionsMessage}}
        loadingMessage={() => 'Зачекайте...'}
        placeholder={'Напрямок'}
        isClearable={true}
        isLoading={resultsLoading}
        value={savedFilter?.directions?.value ? savedFilter?.directions : null}
        onChange={handleOnChange}
        onInputChange={(e) => {
          filterDirections(e)
        }}
      />
    </div>
  )
}

export default DirectionFilter
