/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {usePageData} from '../../../layout/core'
import * as Yup from 'yup'
import {FormattedMessage, useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../../../app/modules/auth'
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

const stripePromise = loadStripe(
  'pk_test_51PFwVLRsJiWODsNLCgBpdI7QcwrVBVbtgjOoeDT5tIuawn9tKN1GSFEHLgQN02IerftnDNXV0TKO1TDlVTpkrAte00e2OOoxBN'
)

export const checkoutSchema = (intl: any) =>
  Yup.object().shape({
    paymentOption: Yup.string(),
    sum: Yup.number().required(intl.messages.YUP_VALIDATION_PAYMENT_ENTER_SUM).positive(),
    comment: Yup.string(),
  })

const CheckoutStripeModal: FC = () => {
  const intl = useIntl()
  const {pageCustomData, setPageCustomData, addNotification} = usePageData()

  const handleClose = () => setPageCustomData((prev: any) => ({...prev, checkoutShow: false}))

  // const [stripePromise, setStripePromise] = useState<any>(null)
  // const stripeKey = process.env.REACT_APP_STRIPE_KEY

  // useEffect(() => {
  //   if (stripeKey) {
  //     console.log('set')

  //     setStripePromise(loadStripe(stripeKey))
  //   } else {
  //     console.error('Stripe public key is not defined in environment variables')
  //   }
  // }, [stripeKey])

  return (
    <>
      {pageCustomData?.clientSecret && stripePromise && (
        <Modal
          show={pageCustomData?.checkoutShow}
          onHide={handleClose}
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog w-100 mw-md-auto mw-0 me-0 my-0'
          backdrop={false}
          className='OnePageForm mw-md-auto mw-lg-auto mw-0 p-0 shadow-sm'
        >
          <div
            className='d-flex flex-column justify-content-between modal-content modal-content-container pt-20'
            style={{
              height: '100vh',
              borderRadius: 0,
              overflowY: 'auto',
            }}
          >
            <Modal.Header closeButton className='py-4'>
              {/* <Modal.Title className='d-flex flex-column justify-content-start align-items-start'>
                <FormattedMessage id='PAYMENT.ADD_BUTTON' />
                {pageCustomData?.direction?.paymentProviderType && (
                  <p className='d-block fw-normal fs-6 m-0'>
                    <FormattedMessage
                      id={`PAYMENT.FEE_NOTIFICATION.${pageCustomData?.direction?.paymentProviderType?.toUpperCase()}`}
                      values={{comm: pageCustomData?.direction?.orderPaymentFee || 0}}
                    />
                  </p>
                )}
              </Modal.Title> */}
            </Modal.Header>
            <div
              className='scroll-y bg-white modal-body p-0'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='py-6 text-primary'>
                <div className='w-100'>
                  {/* {JSON.stringify(pageCustomData)} */}
                  {pageCustomData?.paymentProviderType === 'stripe' && (
                    <EmbeddedCheckoutProvider
                      stripe={stripePromise}
                      options={{clientSecret: pageCustomData?.clientSecret}}
                    >
                      <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export {CheckoutStripeModal}
