import {IOrder} from './_models'

function isIOrderType(order: any): order is IOrder {
  return (order as IOrder).type !== undefined
}

function isIOrderProds(order: any): order is IOrder {
  return (order as IOrder).products !== undefined
}

function isIOrderWebsiteShop(order: any): order is IOrder {
  return (order as IOrder).websiteShop !== undefined
}

function isIOrderNumber(order: any): order is IOrder {
  return (order as IOrder).number !== undefined
}

function isIOrderDirection(order: any): order is IOrder {
  return (order as IOrder).direction !== undefined
}

function isIOrderParcel(order: any): order is IOrder {
  return (order as IOrder).parcel !== undefined
}

export const orderTypeguards = {
  isIOrderType,
  isIOrderWebsiteShop,
  isIOrderProds,
  isIOrderDirection,
  isIOrderParcel,
  isIOrderNumber,
}
