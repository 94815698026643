import {Dispatch, SetStateAction} from 'react'
import {IBasicReceiver} from '../../../../app/pages/receivers/core/_models'
import {ID, Response} from '../../crud-helper/models'
import {IBasicSelect} from './generalHelpers'
import {IShipmentAddressForm} from './profileHelpers'

// vector
export interface IAddressCities {
  id: string
  region: string
  country: string
  name: string
}

export interface ICityStreet {
  ref: string
  name: string
}

export interface INovaPoshtaOffice {
  id: string | number
  big: boolean
  name: string
  number: string | number | null
  pmw: string | number //Place max weight
  tmw: string | number //Total max weight
}

export interface IPostShipmentTitles {
  title?: string
  cityTitle?: string
  officeTitle?: string
  choosePlaceholder?: string
}

export const postMainShipmentDefault = {
  title: 'COMPONENTS.PostShipment.MAIN_NP_TITLE',
  cityTitle: 'COMPONENTS.PostShipment.CITY',
  officeTitle: 'COMPONENTS.PostShipment.NP',
  choosePlaceholder: 'COMPONENTS.PostShipment.NP_PLACEHOLDER',
}

export const postReserveShipmentDefault = {
  title: 'COMPONENTS.PostShipment.RESERVE_NP.TITLE',
  cityTitle: 'COMPONENTS.PostShipment.CITY',
  officeTitle: 'COMPONENTS.PostShipment.NP',
  choosePlaceholder: 'COMPONENTS.PostShipment.NP_PLACEHOLDER',
}
export interface IPostShimpentProps {
  offLoading: boolean
  offResults: IBasicSelect[]
  values: Partial<IShipmentAddressForm>
  setQuery: Dispatch<SetStateAction<string>>
  titles: IPostShipmentTitles
  withHeadTitles: boolean
  filter?: string
}
export interface SearchQueryProps {
  searchQuery: string
  setSearchQuery: (e: string) => void
  lsName?: string
}

// crauzer

export interface ICrauzerAddressCities {
  id: string
  region: {name: string; type: string}
  country: string
  name: string
}

export interface ICrauzerNovaPoshtaOffice {
  id: number
  name: string
}

export interface ICountry {
  id: ID
  country: string
  receive: boolean
  send: boolean
  parcelIdentByOrderNumber: boolean
  locationAddressFields: string[]
  receiverTypes: {international: boolean; final: boolean; withinCountry?: boolean}
}

export interface ISenderCountryForm {
  parcelIdentByOrderNumber: 1 | 0
}

export interface IReceiverCountryForm extends Pick<IBasicReceiver, 'receiverType'> {}

export type CountriesQueryResponse = Response<Array<ICountry>>
