import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ChargesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const PAYMENTS_URL = `/charges`
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${PAYMENTS_URL}`

const getCharges = (query: string): Promise<ChargesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ChargesQueryResponse>) => d.data)
}

export {getCharges}
