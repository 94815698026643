import CitiesSelect from '../../../../modules/custom/form-elements/select/CitiesSelect'
import AptOrOffice from '../modules/AptOrOffice'
import HouseAndLetter from '../modules/HouseAndLetter'
import StreetSelect from '../modules/StreetSelect'

const InternationalPhysAddress = () => {
  return (
    <div>
      <div className='d-flex flex-gutter'>
        <CitiesSelect inputName='physicalAddressCity' />
        <StreetSelect inputName='physicalAddressStreet' />
      </div>

      <div className='col d-md-flex flex-gutter'>
        <HouseAndLetter
          houseInputName='physicalAddressHouseNumber'
          letterInputName='physicalAddressHouseLetter'
        />
        <AptOrOffice
          premisesInputName='physicalAddressPremisesType'
          aptInputName='physicalAddressApartment'
          officeInputName='physicalAddressOffice'
        />
      </div>
    </div>
  )
}

export default InternationalPhysAddress
