import clsx from 'clsx'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {TOneOfOrders} from '../../../../../../../pages/orders/_models'
import {useLocation} from 'react-router-dom'

type TProps = {
  order: TOneOfOrders
  addClass?: string
}
const DetailedButton = ({order, addClass}: TProps) => {
  const {pathname} = useLocation()

  return (
    <Link to={`${pathname}/${order.id}`} className={clsx('btn btn-clean btn-details', addClass)}>
      <FormattedMessage id={'DETAILS'} />
    </Link>
  )
}
export default DetailedButton
