import React from 'react'
import {FormattedMessage} from 'react-intl'
import {IParcel} from '../core/_models'

const Content = ({parcel}: {parcel: IParcel}) => {
  return (
    <>
      <div className='text-dark fs-3'> Вміст:</div>
      <div className='table-responsive'>
        <table
          className={`table table-prods table-half-rounded position-relative align-middle fs-6 no-footer`}
        >
          <thead>
            <tr className='text-muted fs-6 gs-0'>
              <th>Найменування</th>
              <th>Ціна</th>
            </tr>
          </thead>
          <tbody className='text-dark fw-normal'>
            {parcel.parcelProducts.length > 0 ? (
              parcel.parcelProducts.map((i) => {
                return (
                  <tr>
                    <td className='border border-color-grey-light border-radius-left-def'>
                      {i.category.name}
                    </td>
                    <td className='border border-color-grey-light border-radius-right-def'>
                      {i.priceForAll}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id='BUYOUTS.LIST.NO_RESULTS' />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Content
