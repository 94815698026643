import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../../../../_metronic/helpers/custom/funcs/directions'
import {IOrderProps} from '../../../../../../../pages/orders/_models'
import {FlagsDirectionBar} from '../../../../../FlagsDirectionBar'
import FlagsDirectionItem from '../../../../../FlagsDirectionItem'

const Country = ({order}: IOrderProps) => {
  return (
    <>
      <p className='d-block d-md-none text-dark fw-normal fs-2 p-0 m-0'>
        <CountryCore order={order} />
      </p>
      <p className='d-none d-md-block text-dark fw-normal fs-md-6 p-0 m-0'>
        <CountryCore order={order} />
      </p>
    </>
  )
}

export default Country

const CountryCore = ({order}: IOrderProps) => {
  const queryClient = useQueryClient()
  const countriesList: Record<string, string> | undefined = queryClient.getQueryData(
    QUERIES.COUNTRY_ABBR_LIST
  )

  return (
    <>
      {' '}
      <FlagsDirectionBar chosenDirections={[{id: order.direction?.id}]} minified={true} />
      {/* {countriesList &&
        order.direction?.countryFrom &&
        order.direction?.countryTo &&
        Object.entries(countriesList)?.length > 0 && (
          <div className={`FlagsDirectionBar me-2 px-0 mb-0`}>
            <FlagsDirectionItem
              from={order.direction?.countryFrom}
              to={order.direction?.countryTo}
              minified={true}
            />
            <p className='fw-normal text-dark mb-0'>
              {directionsFuncs.formDirection(
                order.direction?.countryFrom,
                order.direction?.countryTo,
                countriesList
              )}
            </p>
          </div>
        )} */}
    </>
  )
}
