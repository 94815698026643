import HeaderNavigation from '../../../modules/HeaderNavigation'
import ChargesListFilter from './ChargesListFilter'

const ChargesListToolbar = () => {
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <div className='me-3'>
        <HeaderNavigation />
      </div>
      <ChargesListFilter />
    </div>
  )
}

export {ChargesListToolbar}
