import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {IOneOfOrders} from '../../../../../../../pages/orders/_models'

const Invoice = ({order}: IOneOfOrders) => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <div className='h-75 w-100 bg-gray-100 px-2 py-8 border-radius-def mb-2'>
        <KTSVG path='/media/company-specific/pokupka/photo-camera.svg' className='svg-icon-4x' />
        {/* <FileInput
                      title={intl.formatMessage({id: 'ORDERS.ADD_INVOICE'})}
                      formikHook={false}
                      inputName='image'
                      inputId={`add-invoice-${row.index}`}
                      uploadedFile={{
                        base: invoice?.base_url || '',
                        link: invoice?.filename || '',
                      }}
                      uploadFunction={saveImage}
                      deleteFunction={deleteImage}
                      required={true}
                      loading={invoiceIsLoading}
                      linkEndpoint='/invoice' // suggested name, no endpoint for now
                    /> */}
      </div>
      <span className='text-decoration-underline fw-normal text-custom-additional'>
        <FormattedMessage id='ORDERS.ADD_INVOICE' />
      </span>
    </div>
  )
}

export default Invoice
