import {FieldArray} from 'formik'
import {GoodsItem} from './GoodsItem'
import _ from 'lodash'
import {
  ISetFieldValue,
  TCurrencyData,
  TFooterNotification,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import currenciesLocal from '../../../../../data/general/currencies.json'
import {ListViewContextProps} from '../../../../../_metronic/helpers'
import {IFormProd} from '../../../../pages/orders/_prod-models'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {Direction} from '../../../../../_metronic/helpers/custom/tsHelpers/directionsHelpers'
import {OrderTypes} from '../orders/OrdersList'

interface Props {
  prods: IFormProd[]
  setFieldValue: ISetFieldValue
  values: {prods: IFormProd[]; direction?: Partial<Direction>}
  listView: ListViewContextProps
  setFooterNotification?: Dispatch<SetStateAction<TFooterNotification>>
  orderType?: OrderTypes
}

export function Goods({
  prods,
  setFieldValue,
  values,
  listView,
  setFooterNotification,
  orderType,
}: Props) {
  const {itemIdForUpdate} = listView

  useEffect(() => {
    if (setFooterNotification && orderType === 'buyout') {
      const prodsTotalCurrValue = values.prods.reduce((acc, i) => acc + +i.total, 0)

      const minBuyotPrice = values?.direction?.minBuyoutPrice
      if (minBuyotPrice && prodsTotalCurrValue < minBuyotPrice) {
        const currSymbols: TCurrencyData = currenciesLocal

        const buyoutCurrSymbol =
          values.direction?.buyoutCurrency &&
          currenciesLocal.hasOwnProperty(values.direction?.buyoutCurrency)
            ? `${currSymbols[values.direction.buyoutCurrency].symbol}`
            : ''
        setFooterNotification({
          color: 'danger',
          success: true,
          icon: '/media/company-specific/pokupka/warning.svg',
          intl: {
            id: 'BUYOUTS.MODAL.HEADER.MIN_PRICE_TOOLTIP',
            values: {
              min: `${buyoutCurrSymbol}${minBuyotPrice}`,
              curr: `${buyoutCurrSymbol}${prodsTotalCurrValue}`,
            },
          },
        })
      } else {
        setFooterNotification(null)
      }
    }
  }, [values?.prods])

  return (
    <FieldArray
      name='prods'
      render={(arrayHelpers) => (
        <div className='d-flex flex-column flex-col-gutter'>
          {prods.map((item, index) => (
            <GoodsItem
              key={index}
              index={index}
              item={item}
              arrayHelpers={arrayHelpers}
              prods={prods}
              setFieldValue={setFieldValue}
              values={values}
              listView={listView}
            />
          ))}
        </div>
      )}
    />
  )
}
