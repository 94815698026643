import {FC, useMemo} from 'react'
import {ID} from '../../../../../../../../../_metronic/helpers'
import {useListView} from '../../../../../../../../pages/orders/buyouts/core/ListViewProvider'
import {TOneOfOrders} from '../../../../../../../../pages/orders/_models'

type Props = {
  order: TOneOfOrders
}

const SelectionCell: FC<Props> = ({order}) => {
  const {selected, onSelect} = useListView()
  const isSelected = useMemo(() => selected.find((i) => i.id === order?.id), [order.id, selected])
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={isSelected}
        onChange={() => onSelect({id: order.id, data: order})}
      />
    </div>
  )
}

export {SelectionCell}
