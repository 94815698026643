import {FC, useMemo} from 'react'
import {useListView} from '../../../../core/ListViewProvider'
import {ICharge} from '../../../../core/_models'

type Props = {
  charge: ICharge
}

const SelectionCell: FC<Props> = ({charge}) => {
  const {selected, onSelect} = useListView()
  const isSelected = useMemo(
    () => selected.find((i) => i.id === charge?.id),
    [charge.id, selected]
  )
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={isSelected}
        onChange={() => onSelect({id: charge.id, data: charge})}
      />
    </div>
  )
}

export {SelectionCell}
