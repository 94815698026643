import {useListView} from '../../../core/ListViewProvider'
import {ReceiversListToolbar} from './ReceiversListToolbar'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import ListSearch from '../../../../../modules/custom/lists/ListSearch'
import {Link, useLocation} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'

const ReceiversListHeader = () => {
  const {pathname} = useLocation()

  return (
    <div className='card-header border-0 justify-content-end shadow-pokupka p-5'>
      {!pathname.includes('new') && (
        <div className='card-toolbar my-0'>
          {/* begin::Group actions */}
          {/* {selected.length > 0 ? <OrdersListGrouping /> :*/} <ReceiversListToolbar />
          {/* end::Group actions */}
        </div>
      )}

      <Link
        to={'/receivers/new'}
        className='d-flex btn btn-clean btn-shadow shadow-pokupka px-2 text-custom-accent fw-bold with-icon'
      >
        {' '}
        <KTSVG
          path='/media/company-specific/pokupka/plus.svg'
          className='svg-icon-4 icon svg-icon-custom-white d-flex align-items-center justify-content-center'
          svgClassName='svg'
        />
        <span className='px-4'>
          <FormattedMessage id='RECEIVERS.PAGE.LIST.ADD_BUTTON' />
        </span>
      </Link>
    </div>
  )
}

export {ReceiversListHeader}
