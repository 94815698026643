import {ErrorMessage, Field, useFormikContext} from 'formik'
import {FormattedMessage} from 'react-intl'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'

const AptOrOffice = ({
  premisesInputName = 'premisesType',
  aptInputName = 'apartment',
  officeInputName = 'office',
}) => {
  const {values} = useFormikContext<Record<string, any>>()
  return (
    <div className='w-100 w-md-50'>
      <div className='d-flex w-100'>
        <div className='me-4 w-50 '>
          <div className='d-flex align-items-center w-100'>
            <label className='d-flex fs-6 me-2'>
              <span>
                <FormattedMessage id='COMPONENTS.AddressShipment.APARTMENT' />
              </span>
            </label>
            <Field
              className='form-check-input'
              type='radio'
              name={premisesInputName}
              value='apartment'
            />
          </div>
          {values[premisesInputName] === 'apartment' && (
            <InputTemplate
              required={false}
              type='text'
              inputName={aptInputName}
              marginClass='me-4'
            />
          )}
        </div>
        <div className='w-50'>
          {' '}
          <div className='d-flex align-items-center'>
            {' '}
            <label className='d-flex align-items-center fs-6 me-2 '>
              <span>
                <FormattedMessage id='COMPONENTS.AddressShipment.OFFICE' />
              </span>
            </label>
            <Field
              className='form-check-input'
              type='radio'
              name={premisesInputName}
              value='office'
            />
          </div>
          {values[premisesInputName] === 'office' && (
            <div>
              <InputTemplate required={false} type='text' inputName={officeInputName} />{' '}
            </div>
          )}
        </div>
      </div>
      <div className='text-danger'>
        <ErrorMessage name={premisesInputName} />
      </div>
    </div>
  )
}

export default AptOrOffice
