import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {Field} from 'formik'
import {ModalNotification} from '../../../../modules/custom/modals/ModalNotification'

interface Props {
  setFieldValue: ISetFieldValue
  typeValue: number
  chosenTypes: number[]
  title: string
  noteText?: string
  disabled: boolean
}

function ReceiverTypeCheckbox({
  setFieldValue,
  chosenTypes,
  typeValue,
  title,
  noteText,
  disabled,
}: Props) {
  return (
    <div className='d-flex'>
      <label
        className='d-flex position-relative fs-6 justify-content-left     
         me-1 align-items-center'
      >
        <Field
          className='form-check-input me-2'
          disabled={disabled}
          type='checkbox'
          name='receiverType'
          value={typeValue}
          onChange={(e: any) => {
            const val = parseInt(e.target.value)
            setFieldValue(
              'receiverType',
              !chosenTypes.includes(typeValue)
                ? [...chosenTypes, val]
                : chosenTypes.filter((i) => i !== val)
            )
          }}
          checked={chosenTypes.includes(typeValue)}
        />{' '}
        <div>
          <span className='fs-6 fw-normal'>{title}</span>
        </div>
      </label>
      {noteText && (
        <ModalNotification
          svg='/media/icons/duotune/general/gen046.svg'
          addClass='ModalNotification__position ModalNotification__receiver-checkbox'
          children={<div className='fs-7 fw-normal'>{noteText}</div>}
        />
      )}
    </div>
  )
}

export {ReceiverTypeCheckbox}
