import {useQueries, useQuery, useQueryClient} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {KTCard, QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from '../../../_metronic/helpers/custom/api/directionsApi'
import {locationsApi} from '../../../_metronic/helpers/custom/api/locationsApi'
import {OrdersListHeader} from '../../modules/custom/sections/orders/list/components/header/OrdersListHeader'
import {useListView} from './core/ListViewProvider'
import ParcelPage from './parcel-page/ParcelPage'
import {ParcelsListWrapper} from './parcels-list/ParcelsListWrapper'

const ParcelsPage = () => {
  const location = useLocation()
  const {pathname} = location

  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  useQuery([
    {
      queryKey: [QUERIES.DIRECTIONS_LIST],
      queryFn: () => directionsApi.fetchDirectionsWithIds(countries),
    },
  ])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path={'/:id'} element={<ParcelPage />} />
      </Route>
      <Route
        index
        element={
          <KTCard className='list-card'>
            <ParcelsListWrapper orderType='parcel' />
          </KTCard>
        }
      />
    </Routes>
  )
}

export default ParcelsPage
