import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {selectDebounce} from '../../../../../_metronic/helpers'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'

const StreetSelect = ({inputName = 'street'}) => {
  const intl = useIntl()
  const {setFieldValue, values} = useFormikContext<Record<string, any>>()
  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterStreets = useCallback(
    selectDebounce((query: string, callback?: any) => {
      setResultsLoading(true)

      locationsApi
        .getAddressCities(query)
        .then((res) => {
          if (res) {
            console.log('res cities', res?.data?.data)

            setResults(res)

            if (callback) {
              callback(res)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [locationsApi.getAddressCities]
  )

  useEffect(() => {
    filterStreets('')
    setFieldValue(inputName, {value: '', label: ''})
  }, [values.city])
  return (
    <InputTemplate
      inputName={inputName}
      required={true}
      type='select'
      title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.STREET'})}
      reactSelectprops={{
        currentValue: values[inputName] || results[0],
        options: results,
        isLoading: resultsLoading,
        onInputChange: filterStreets,
        isClearable: true,
        selectName: inputName,
        setFieldValue: setFieldValue,
        placeholder: intl.formatMessage({id: 'COMPONENTS.AddressShipment.STREET_PLACEHOLDER'}),
        setObject: true,
        isCreatable: true,
      }}
    />
  )
}

export default StreetSelect
