/* eslint-disable jsx-a11y/anchor-is-valid */
import {OrdersListHeader} from '../../../../modules/custom/sections/orders/list/components/header/OrdersListHeader'
import {OrdersList, OrderTypesProps} from '../../../../modules/custom/sections/orders/OrdersList'
import {ListViewProvider} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'

const TracksListWrapper = ({orderType}: OrderTypesProps) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <OrdersListHeader />
          <OrdersList orderType={orderType} />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {TracksListWrapper}
