import {FormattedMessage} from 'react-intl'
import {statusColor} from '../../../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {IOrderProps} from '../../../../../../../../pages/orders/_models'

const StatusCell = ({order}: IOrderProps) => {
  const {} = order

  return (
    <div className='text-center'>
      {order.paid && (
        <span
          className={`px-1 fs-7 fw-semobold  StatusBar__status cursor-pointer ${statusColor(true)}`}
        >
          <FormattedMessage id='GENERAL.STATUSES.PAID' />
        </span>
      )}
    </div>
  )
}

export {StatusCell}
