import {TCurrencyData} from '../../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import currenciesLocal from '../../../../../../../../data/general/currencies.json'
import {IOneOfOrders} from '../../../../../../../pages/orders/_models'
import {orderTypeguards} from '../../../../../../../pages/orders/_typeguards'
import {parcelTypeguards} from '../../../../../../../pages/parcels/core/_typeguards'

const TotalSum = ({order}: IOneOfOrders) => {
  // const currSymbols: TCurrencyData = currenciesLocal
  // const currSymbol =
  //   order?.direction?.buyoutCurrency &&
  //   currenciesLocal.hasOwnProperty(order.direction?.buyoutCurrency)
  //     ? `${currSymbols[order?.direction?.buyoutCurrency].symbol}`
  //     : ''

  const buyoutCurrency = orderTypeguards.isIOrderDirection(order)
  return (
    <span className='fs-6 text-dark fw-normal'>
      {orderTypeguards.isIOrderProds(order) &&
        order.products.length > 0 &&
        `${order.products?.reduce((acc, i) => acc + i.priceForAll, 0)} ${
          buyoutCurrency && order.direction?.buyoutCurrency ? order.direction?.buyoutCurrency : ''
        }`}
      {!!parcelTypeguards.isIParcelProds(order) &&
        order.parcelProducts.length > 0 &&
        `${order.parcelProducts?.reduce((acc, i) => acc + i.priceForAll, 0)} ${
          buyoutCurrency && order.direction?.buyoutCurrency ? order.direction?.buyoutCurrency : ''
        }`}
    </span>
  )
}

export default TotalSum
