import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {ICharge} from '../../../core/_models'
import {SelectionHeader} from './SelectionHeader'
import {SelectionCell} from './cells/SelectionCell'
import {DateCell} from './cells/DateCell'
import {NumberCell} from './cells/NumberCell'
import {DirectionCell} from './cells/DirectionCell'
import {SumCell} from './cells/SumCell'

const chargesColumns: ReadonlyArray<Column<ICharge>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) =>
      props.data[props.row.index].allowPay ? (
        <SelectionCell charge={props.data[props.row.index]} />
      ) : (
        <></>
      ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'ORDERS.DATE'} className='min-w-125px' />
    ),
    id: 'date',
    Cell: ({...props}) => <DateCell charge={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'FINANCES.NUMBER'} className='min-w-125px' />
    ),
    id: 'number',
    Cell: ({...props}) => <NumberCell charge={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'FINANCES.DIRECTION'} className='min-w-150px' />
    ),
    id: 'direction',
    Cell: ({...props}) => <DirectionCell charge={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'FINANCES.SUM'} className='min-w-125px' />
    ),
    id: 'sum',
    Cell: ({...props}) => <SumCell charge={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'RECEIVERS_COLUMNS_CITY'}
  //       className='min-w-125px text-center'
  //     />
  //   ),
  //   id: 'city',
  //   Cell: ({...props}) => <ReceiverCityCell receiver={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'RECEIVERS_COLUMNS_ADDRESS'}
  //       className='min-w-125px text-center'
  //     />
  //   ),
  //   id: 'address',
  //   Cell: ({...props}) => <ReceiverAddressCell receiver={props.data[props.row.index]} />,
  // },

  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'RECEIVERS_COLUMNS_RECEIVER_TYPE'}
  //       className='min-w-80px text-center'
  //     />
  //   ),
  //   id: 'receiverType',
  //   Cell: ({...props}) => <ReceiverTypeCell receiver={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'RECEIVERS_COLUMNS_VERIFIED'}
  //       className='min-w-80px text-center'
  //     />
  //   ),
  //   id: 'verified',
  //   Cell: ({...props}) => <ReceiverVerifiedCell receiver={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'RECEIVERS_COLUMNS_STATUS'}
  //       className='min-w-125px text-center'
  //     />
  //   ),
  //   id: 'status',
  //   Cell: ({...props}) => <ReceiverStatusCell receiver={props.data[props.row.index]} />,
  // },
]

export {chargesColumns}
