import {useEffect, useState} from 'react'
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {ThemeModeProvider} from '../partials'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dropdown} from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {Notifications} from '../partials/notifications/Toasts'
import {TermsOfServiceModal} from '../../app/pages/profile/pokupka/components/TermsOfServiceModal'
import {FormattedMessage} from 'react-intl'
import {CheckoutStripeModal} from '../partials/modals/balance/CheckoutStripeModal'
import AddButton from '../../app/modules/custom/AddButton'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <Notifications />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div
                className='app-main flex-column justify-content-between flex-row-fluid'
                id='kt_app_main'
              >
                {/* <ToolbarWrapper /> */}
                <Content>
                  <Outlet />
                </Content>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <RightToolbar />
        {/* <ActivityDrawer />
        <DrawerMessenger /> */}
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        {/* <InviteUsers />
        <UpgradePlan /> */}
        <CheckoutStripeModal />
        <AddButton direction='up' floating={true} full={false} />
        <TermsOfServiceModal />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
