import {FormattedMessage} from 'react-intl'
import {IOneOfOrders} from '../../../../../../../pages/orders/_models'

type TProps = {
  withTitle?: boolean
}
const Statuses = ({order, withTitle = true}: IOneOfOrders & TProps) => {
  return (
    <>
      {withTitle && (
        <div className='text-right fw-normal mb-3 ms-n3 fs-7'>
          <FormattedMessage id={'ORDERS.LAST_STATUSES'} />
        </div>
      )}
      <div className='timeline row no-gutters my-2'>
        <div className='item' />
        <div className='item' />
        <div className='item' />
        <div className='item' />
        <div className='item active' />
      </div>
    </>
  )
}

export default Statuses
