import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import {ErrorMessage, useFormikContext} from 'formik'
import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {customStyles} from './ReactSelectTemplate'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'
import {objectToArray} from '../../../../../_metronic/helpers/custom/funcs/objectToArray'
import {useQueryClient} from 'react-query'
import {QUERIES, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import flags from '../../../../../data/general/flags.json'
import {ICountry} from '../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'

interface Props {
  options: (IBasicSelect & Partial<ICountry>)[]
  isLoading: boolean
  currentValue?: (IBasicSelect & Partial<ICountry>) | {value: null; label: null}
  noOptionsMessage?: string
  disabled?: boolean
  setCountriesLoading: Dispatch<SetStateAction<boolean>>
  setCountriesResults: Dispatch<SetStateAction<(IBasicSelect & Partial<ICountry>)[]>>
  title?: boolean
  onInputChange: (query: string) => void
  onChange?: (arg: any) => void
  addContClass?: string
  titleClass?: string
  placeholder?: string
  queryFilter?: string
}

export const CountriesSelect = ({
  options,
  isLoading,
  currentValue,
  noOptionsMessage,
  disabled = false,
  setCountriesLoading,
  setCountriesResults,
  title = true,
  onInputChange,
  onChange,
  addContClass = 'w-75 mb-4 mt-1',
  titleClass,
  placeholder,
  queryFilter = 'filter[send]=true&filter[receive]=false',
}: Props) => {
  const intl = useIntl()

  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>
          {noOptionsMessage ? noOptionsMessage : 'Не знайдено'}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      callback(options)
      return
    }

    setCountriesLoading(true)
    locationsApi
      .getAvailableCountries(queryFilter)
      .then((res) => {
        if (res?.data) {
          setCountriesResults(
            res?.data?.map((i) => ({...i, value: i.id, label: countries[i.country]}))
          )
          callback(res?.data || [])
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setCountriesLoading(false))
  }

  const formik = useFormikContext<any>()

  const handleOnChange = (selectedValue: (IBasicSelect & Partial<ICountry>) | null) => {
    console.log('selectedValue', selectedValue)
    if (onChange) {
      onChange(selectedValue)
      return
    } else if (formik && selectedValue !== null) {
      formik?.setFieldValue('countryConfig', selectedValue)
    } else {
      formik?.setFieldValue('countryConfig', '')
    }
  }

  const optionFormat = (item: IBasicSelect & Partial<ICountry>) => {
    const {label, country} = item
    return (
      <div className='tagify__dropdown__item d-flex align-items-center'>
        <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer'>
          <img
            alt=''
            className='rounded-circle w-25px me-2'
            src={toAbsoluteUrl(`${flags.find((item) => item.country === country)?.flag}`)}
          />
        </div>

        <div className='d-flex flex-column text-gray-800'>{label}</div>
      </div>
    )
  }

  return (
    <div
      className={`fv-row d-flex
      flex-column ${addContClass}`}
    >
      <AsyncSelect
        styles={{
          ...customStyles,
          control: (provided) => {
            return {...provided, minHeight: '40px', height: '40px', borderRadius: '0'}
          },
        }}
        formatOptionLabel={optionFormat}
        className='form-control form-control-lg border-0 p-0 fw-normal fs-6'
        loadOptions={loadOptions}
        defaultOptions={options}
        form={'countryConfig'}
        isDisabled={disabled}
        components={{NoOptionsMessage, IndicatorSeparator: () => null}}
        loadingMessage={() => 'Зачекайте...'}
        placeholder={placeholder ? intl.formatMessage({id: placeholder}) : ''}
        isClearable={true}
        isLoading={isLoading}
        value={currentValue?.value ? currentValue : null}
        onChange={handleOnChange}
        onInputChange={(e) => {
          onInputChange(e)
        }}
      />

      {formik && (
        <div className='text-danger'>
          <ErrorMessage name={'countryConfig.label'} />
        </div>
      )}
    </div>
  )
}
