import {GoodsItemBody} from './GoodsItemBody'
import {useFormikContext} from 'formik'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ListViewContextProps} from '../../../../../_metronic/helpers'
import {AddBuyoutForm} from '../../../../pages/orders/buyouts/core/_buyout-models'
import {IFormProd} from '../../../../pages/orders/_prod-models'

interface Props {
  item: IFormProd
  index: number
  arrayHelpers: any
  prods: IFormProd[]
  setFieldValue: ISetFieldValue
  values: {prods: IFormProd[]}
  listView: ListViewContextProps
}

export function GoodsItem({
  item,
  index,
  arrayHelpers,
  prods,
  setFieldValue,
  values,
  listView,
}: Props) {
  const {itemIdForUpdate} = listView
  const {errors} = useFormikContext<AddBuyoutForm>()

  return (
    <>
      {/* {index !== prods.length - 1 || itemIdForUpdate ? (
        <Accordion>
          <Accordion.Item eventKey={index.toString()}>
            <div
              className={`custom-recent-invoices-accordion-header ${
                errors.prods && errors.prods[index] ? 'error' : ''
              }`}
            >
              <Accordion.Header
                style={{
                  cursor: 'pointer',
                  width: '91.5%',
                }}
              >
                <TrackGoodsItemMinifiedHeader item={item} />
              </Accordion.Header>
            </div>

            <Accordion.Body>
              <TrackGoodsItemBody
                index={index}
                item={item}
                arrayHelpers={arrayHelpers}
                prods={prods}
                setFieldValue={setFieldValue}
                values={values}
                listView={listView}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : ( */}
      <GoodsItemBody
        index={index}
        item={item}
        arrayHelpers={arrayHelpers}
        prods={prods}
        setFieldValue={setFieldValue}
        values={values}
        listView={listView}
      />
      {/* )} */}
    </>
  )
}
