import {ListViewProvider} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../_metronic/helpers'
import {ChargesTable} from './table/ChargesTable'
import {ChargesListHeader} from './components/header/ChargesListHeader'
import {useEffect} from 'react'

const ChargesList = () => {
  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.CHARGES_LIST)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.CHARGES_LIST),
      JSON.stringify(savedFilter)
    )
  }, [])

  return (
    <KTCard>
      <ChargesListHeader />

      <ChargesTable />
    </KTCard>
  )
}

const ChargesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ChargesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ChargesListWrapper}
