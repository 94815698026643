import {ErrorMessage, useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'

const DelTypeRadios = () => {
  const intl = useIntl()
  const {setFieldValue, values} = useFormikContext<{typeOfDelivery: number}>()
  return (
    <>
      <p className='required fs-6 mb-4'>
        <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DEL_TYPE_LABEL' />
      </p>
      <div className='d-flex'>
        {' '}
        <InputTemplate
          inputName='typeOfDelivery'
          required={false}
          type='radio'
          value={1}
          title={intl.formatMessage({
            id: 'RECEIVERS.ADD_REC_MODAL.ADDRESS_DELIVERY',
          })}
          checkboxProps={{
            checked: values.typeOfDelivery === 1,
            disabled: false,
            setFieldValue: setFieldValue,
            value: 1,
            inputName: 'typeOfDelivery',
            type: 'radio',
          }}
        />
        <InputTemplate
          inputName='typeOfDelivery'
          required={false}
          type='radio'
          value={2}
          title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NP_DELIVERY'})}
          checkboxProps={{
            checked: values.typeOfDelivery === 2,
            disabled: false,
            setFieldValue: setFieldValue,
            value: 2,
            inputName: 'typeOfDelivery',
            type: 'radio',
          }}
        />
      </div>

      <div className='text-danger'>
        <ErrorMessage name='typeOfDelivery' />
      </div>
    </>
  )
}

export default DelTypeRadios
