import React from 'react'
import {useIntl} from 'react-intl'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'

const HouseAndLetter = ({houseInputName = 'houseNumber', letterInputName = 'houseLetter'}) => {
  const intl = useIntl()
  return (
    <div className='d-flex w-100 w-md-50'>
      <InputTemplate
        required={true}
        type='text'
        inputName={houseInputName}
        marginClass='me-4'
        title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.HOUSE'})}
      />{' '}
      <InputTemplate
        required={false}
        type='text'
        inputName={letterInputName}
        title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.LETTER'})}
      />{' '}
    </div>
  )
}

export default HouseAndLetter
