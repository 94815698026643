/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {Overlay, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {IOneOfOrders} from '../../../../../../../../pages/orders/_models'
import {orderTypeguards} from '../../../../../../../../pages/orders/_typeguards'
import {parcelTypeguards} from '../../../../../../../../pages/parcels/core/_typeguards'
import TotalSum from '../../modules/TotalSum'
import Weight from '../../modules/Weight'

const NumberCell = ({order}: IOneOfOrders) => {
  const {weight} = order
  const products = orderTypeguards.isIOrderProds(order)
    ? order.products
    : parcelTypeguards.isIParcelProds(order)
    ? order.parcelProducts
    : []
  return (
    <div className='d-flex flex-column min-w-100px max-w-150px'>
      {orderTypeguards.isIOrderNumber(order) && <Number number={order.number} />}
      {parcelTypeguards.isIParcelCode(order) && <Number number={order.code} />}

      <div className='d-md-none'>
        <Weight order={order} />
        {weight && products?.length > 0 && <span> / </span>}
        <TotalSum order={order} />
      </div>
    </div>
  )
}

export {NumberCell}

const Number = ({number}: {number: string}) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const target = useRef(null)
  const numberRef = useRef(null)
  return (
    <div className='d-flex align-items-center'>
      <span className='d-block d-md-none text-dark fw-normal fs-1 me-2' ref={numberRef}>
        {number}
      </span>
      <span className='d-none d-md-block text-dark fw-normal fs-md-5 me-2' ref={numberRef}>
        {number}
      </span>
      <div
        ref={target}
        onClick={() => {
          copyToClipboard(numberRef, setShow)
          setTimeout(() => {
            setShow(false)
          }, 1000)
        }}
      >
        <KTSVG
          path='/media/icons/duotune/general/gen054.svg'
          className={`svg-icon-3 ${show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'}`}
        />
      </div>{' '}
      <Overlay target={target.current} show={show} placement='right'>
        {(props) => (
          <Tooltip {...props}>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})} </Tooltip>
        )}
      </Overlay>
    </div>
  )
}
