/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Receiver} from '../../../../core/_models'

type Props = {
  receiver: Receiver
}

const ReceiverNameCell: FC<Props> = ({receiver}) => {
  return (
    <div className='d-flex align-items-center'>
      <Link
        to={receiver.id === 0 ? '/profile/overview' : `/receivers/edit/overview/${receiver.id}`}
      >
        {' '}
        <div className='d-flex flex-column'>
          {!receiver.patronymic && !receiver.lastName && !receiver.firstName ? (
            '-'
          ) : (
            <>
              <span className='fw-normal text-black d-block fs-6'>
                {receiver.lastName || ''} {receiver.firstName}{' '}
                {receiver.patronymic ? receiver.patronymic : ''}
              </span>
            </>
          )}
        </div>
      </Link>
    </div>
  )
}

export {ReceiverNameCell}
