import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/row/CustomRow'
import {tableColumns} from './columns/_columns'
import {OrdersListLoading} from '../components/loading/OrdersListLoading'
import {OrdersListPagination} from '../components/pagination/OrdersListPagination'
import {FormattedMessage, useIntl} from 'react-intl'
import {OrderTypesProps} from '../../OrdersList'
import {
  useQueryResponse as useQueryResponseBuyouts,
  useQueryResponseData as useQueryResponseDataBuyouts,
  useQueryResponseLoading as useQueryResponseLoadingBuyouts,
} from '../../../../../../pages/orders/buyouts/core/QueryResponseProvider'
import {
  useQueryResponse as useQueryResponseTracks,
  useQueryResponseData as useQueryResponseDataTracks,
  useQueryResponseLoading as useQueryResponseLoadingTracks,
} from '../../../../../../pages/orders/tracks/core/QueryResponseProvider'
import {
  useQueryResponse as useQueryResponseParcels,
  useQueryResponseData as useQueryResponseDataParcels,
  useQueryResponseLoading as useQueryResponseLoadingParcels,
} from '../../../../../../pages/parcels/core/QueryResponseProvider'
import {KTCardBody} from '../../../../../../../_metronic/helpers'
import {TOneOfOrders} from '../../../../../../pages/orders/_models'

const OrdersTable = ({orderType}: OrderTypesProps) => {
  const isBuyout = orderType === 'buyout'
  const isTrack = orderType === 'track'
  const isParcel = orderType === 'parcel'

  const buyouts = useQueryResponseDataBuyouts()
  const tracks = useQueryResponseDataTracks()
  const parcels = useQueryResponseDataParcels()
  const orders = isBuyout ? buyouts : isTrack ? tracks : parcels

  const isLoadingBuyouts = useQueryResponseLoadingBuyouts()
  const isLoadingTracks = useQueryResponseLoadingTracks()
  const isLoadingParcels = useQueryResponseLoadingParcels()

  const isLoading = isBuyout ? isLoadingBuyouts : isTrack ? isLoadingTracks : isLoadingParcels

  const data = useMemo(() => orders, [orders])
  const columns = useMemo(() => tableColumns, [])
  const intl = useIntl()

  const {response: buyoutsTotal} = useQueryResponseBuyouts()
  const {response: tracksTotal} = useQueryResponseTracks()
  const {response: parcelsTotal} = useQueryResponseParcels()

  const ordersTotal = isBuyout ? buyoutsTotal : isTrack ? tracksTotal : parcelsTotal

  const hiddenColumns = window.matchMedia('(max-width: 667px)').matches
    ? [
        'country',
        'logo',
        'weight',
        'cost',
        'status',
        'actions',
        'ttn',
        !isBuyout ? 'selection' : '',
      ]
    : window.matchMedia('(max-width: 991px)').matches
    ? ['logo', 'cost', 'status', 'ttn', !isBuyout ? 'selection' : '']
    : [!isBuyout ? 'logo' && 'selection' : '', !isParcel ? 'ttn' : '']

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
    initialState: {hiddenColumns},
  })

  return (
    <KTCardBody className='py-7 px-4 px-md-9'>
      <div className='d-none d-md-block'>
        {ordersTotal?.totalItems !== undefined && ordersTotal?.itemsPerPage !== undefined && (
          <OrdersListPagination
            showPagination={ordersTotal?.totalItems > ordersTotal?.itemsPerPage}
            showItemsPerPage={ordersTotal?.totalItems <= 10 ? false : true}
          />
        )}
      </div>
      <div className={`table-responsive ${rows.length > 0 ? 'mb-4' : ''}`}>
        <table
          id='kt_table_orders'
          className={`table table-orders table-half-rounded position-relative align-middle fs-6 no-footer ${`${orderType}s-table`}`}
          {...getTableProps()}
        >
          {rows.length > 0 && (
            <thead className='d-none d-md-table-header-group'>
              <tr className='text-muted fs-6 gs-0'>
                {headers.map((column: ColumnInstance<TOneOfOrders>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
          )}
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<TOneOfOrders>, i) => {
                prepareRow(row)
                return <CustomRow row={row} orderType={orderType} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id={`${orderType.toUpperCase()}S.LIST.NO_RESULTS`} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {ordersTotal?.totalItems !== undefined && ordersTotal?.itemsPerPage !== undefined && (
        <OrdersListPagination
          showPagination={ordersTotal?.totalItems > ordersTotal?.itemsPerPage}
          showItemsPerPage={ordersTotal?.totalItems <= 10 ? false : true}
          addClass='mt-4 mt-md-0'
        />
      )}
      {isLoading && <OrdersListLoading />}
    </KTCardBody>
  )
}

export {OrdersTable}
