import {useFormikContext} from 'formik'
import {Dispatch, SetStateAction, useState} from 'react'
import {useIntl} from 'react-intl'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'
import {ICrauzerProfileForm} from '../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {
  IBasicSelect,
  TFooterNotification,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useAuth} from '../../../../modules/auth'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {FileInput} from '../../../../modules/custom/form-elements/file-inputs/FileInput'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {ProfileEmail} from './contacts/ProfileEmail'
import {ProfileNumberItem} from './contacts/ProfileNumberItem'

type TProps = {
  loading: boolean
  success: boolean
  imageIsLoading: boolean
  saveImage: any
  deleteImage: any
  footerNotification: TFooterNotification
}

const ProfileAddress = ({
  loading,
  success,
  imageIsLoading,
  saveImage,
  deleteImage,
  footerNotification,
}: TProps) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {values, setFieldValue} = useFormikContext<Partial<ICrauzerProfileForm>>()
  const [citiesLoading, setCitiesLoading] = useState(false)
  const [citiesResults, setCitiesResults] = useState<IBasicSelect[]>([])

  const filterCities = async (query: string) => {
    setCitiesLoading(true)
    await locationsApi
      .getAddressCities(query)
      .then((res) => {
        setCitiesResults(res)
      })
      .catch((err) => console.log(err))
      .finally(() => setCitiesLoading(false))
  }

  return (
    <div className='col-md-6 col-lg-8 profile-address-wrapper'>
      <div className='form-group user-id d-none d-md-flex w-100'>
        ID: {currentUser?.externalId || ''}
      </div>
      <div className='profile-address-container d-flex flex-wrap justify-content-md-between mb-2'>
        <div className='w-100 w-lg-50 pe-lg-5'>
          <InputTemplate
            inputName='country'
            type='select'
            value={values.country}
            title={'Оберіть країну'}
            reactSelectprops={{
              options: citiesResults,
              isLoading: citiesLoading,
              onInputChange: filterCities,
              isClearable: true,
              selectName: 'country',
              setFieldValue: setFieldValue,
              placeholder: 'Країна',
              currentValue: citiesResults.find((item: any) => item.value === values.city),
            }}
          />
        </div>{' '}
        <div className='w-100 w-lg-50 ps-lg-5'>
          {' '}
          <InputTemplate
            inputName='region'
            type='select'
            value={values.region}
            title={'Оберіть область'}
            reactSelectprops={{
              options: citiesResults,
              isLoading: citiesLoading,
              onInputChange: filterCities,
              isClearable: true,
              selectName: 'region',
              setFieldValue: setFieldValue,
              placeholder: 'Область',
              currentValue: citiesResults.find((item: any) => item.value === values.city),
            }}
          />
        </div>
        <div className='w-100 w-lg-50 pe-lg-5'>
          <InputTemplate
            inputName='city'
            type='select'
            value={values.city}
            title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.CITY'})}
            reactSelectprops={{
              options: citiesResults,
              isLoading: citiesLoading,
              onInputChange: filterCities,
              isClearable: true,
              selectName: 'city',
              setFieldValue: setFieldValue,
              placeholder: 'Місто',
              currentValue: citiesResults.find((item: any) => item.value === values.city),
            }}
          />
        </div>
        <div className='w-100 w-lg-50 ps-lg-5'>
          <InputTemplate
            title={intl.formatMessage({id: 'PROFILE.STREET_L'})}
            inputName='street'
            required={false}
            type='text'
            placeholder={intl.formatMessage({id: 'PROFILE.STREET'})}
          />
        </div>
        <div className='w-100 w-lg-25'>
          {' '}
          <InputTemplate
            title={intl.formatMessage({
              id: 'PROFILE.ST_NUM_L',
            })}
            inputName='house'
            required={false}
            type='text'
            placeholder={intl.formatMessage({
              id: 'PROFILE.ST_NUM',
            })}
          />{' '}
        </div>
        <div className='w-100 w-lg-25'>
          {' '}
          <InputTemplate
            title={intl.formatMessage({id: 'PROFILE.FLAT_L'})}
            inputName='apt'
            required={false}
            type='text'
            placeholder={intl.formatMessage({
              id: 'PROFILE.FLAT',
            })}
          />
        </div>
        <div className='w-100 w-lg-25 mb-4'>
          <InputTemplate
            title={intl.formatMessage({id: 'PROFILE.INDEX'})}
            inputName='zipCode'
            required={false}
            type='text'
            placeholder={''}
          />
        </div>
        {/* <div className='mb-4 mb-md-8 w-100 w-md-50'>
          <div className='me-0 me-md-10'>
            <ProfileEmail user={currentUser} />
          </div>
        </div>
        <InputTemplate
          title={intl.formatMessage({id: 'PROFILE.PAGE.CONTACTS.NUMBER'})}
          fieldWidth='w-100 w-md-50'
          inputName='phoneNumber'
          type='tel'
          value={values.phoneNumber || ''}
          custom={<ProfileNumberItem user={currentUser} />}
          marginClass='mb-4'
        /> */}
        <div className='d-md-flex justify-content-md-start w-100'>
          {' '}
          <div className='w-100 me-md-5'>
            <InputTemplate
              inputName='deliveryType'
              required={false}
              type='radio'
              value={1}
              title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NP_DELIVERY'})}
              checkboxProps={{
                checked: values.deliveryType === 1 ? true : false,
                disabled: false,
                setFieldValue: setFieldValue,
                value: 1,
                inputName: 'deliveryType',
                type: 'radio',
              }}
            />
            {values.deliveryType === 1 && (
              <InputTemplate
                marginClass='mt-3'
                inputName='postOffice'
                required={true}
                type='select'
                value={values.postOffice}
                reactSelectprops={{
                  options: citiesResults,
                  isLoading: citiesLoading,
                  onInputChange: filterCities,
                  isClearable: true,
                  selectName: 'postOffice',
                  setFieldValue: setFieldValue,
                  placeholder: '',
                  currentValue: citiesResults.find((item: any) => item.value === values.city),
                }}
              />
            )}
          </div>
          <InputTemplate
            inputName='deliveryType'
            required={false}
            type='radio'
            value={2}
            title={"Доставка кур'єром додому"}
            checkboxProps={{
              checked: values.deliveryType === 2 ? true : false,
              disabled: false,
              setFieldValue: setFieldValue,
              value: 2,
              inputName: 'deliveryType',
              type: 'radio',
            }}
          />
        </div>
        <div className='d-block d-md-none d-lg-block mb-md-18'>
          <FileInput
            title={intl.formatMessage({id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE'})}
            inputName='passport'
            inputId={`${currentUser?.externalId}-profile-file-input`}
            uploadedFile={{
              base: values?.passport?.base_url || '',
              link: values?.passport?.filename || '',
            }}
            setFieldValue={setFieldValue}
            uploadFunction={saveImage}
            deleteFunction={deleteImage}
            placeholder={intl.formatMessage({
              id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE',
            })}
            notification={{
              bool: true,
              text: intl.formatMessage({
                id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE.PROMPT',
              }),
              borderTop: false,
            }}
            required={true}
            loading={imageIsLoading}
            linkEndpoint='/passport'
          />
        </div>
      </div>
      <CardFooter
        loading={loading}
        success={footerNotification?.success || false}
        notification={footerNotification}
        addClass='d-none d-md-block'
      />
    </div>
  )
}

export default ProfileAddress
