import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {ID, QueryResponseContextProps, QueryState} from './models'
import {LS_KEYS, TABLE_LIST_FILTER_KEY} from './consts'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&itemsPerPage=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'itemsPerPage'], skipNulls: true})
  const sort =
    isNotEmpty(state.order) && isNotEmpty(state.sort) ? `order[${state.sort}]=${state.order}` : ''
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter[${obj[0]}]=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter].filter((f) => f).join('&')
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(
  data: Array<T> | undefined,
  selected: Array<{id: ID; data?: Record<string, any>}>
): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  item: {id: ID; data?: Record<string, any>},
  selected: Array<{id: ID; data?: Record<string, any>}>,
  setSelected: Dispatch<SetStateAction<Array<{id: ID; data?: Record<string, any>}>>>
) {
  if (!item) {
    return
  }

  const id = selected.find((i) => i?.id && item?.id && +i?.id === +item?.id)?.id

  if (id) {
    console.log('eq ids sel', selected)

    setSelected(selected.filter((item) => item.id !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(item)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<{id: ID; data?: Record<string, any>}>>>,
  data?: Array<T & {id: ID}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }
  // setSelected(
  //   data.filter((item) => item.id !== undefined) as Array<{id: ID; data?: Record<string, any>}>
  // )

  setSelected(data.filter(({id}) => !!id).map((i) => ({id: i.id, data: i})))
}

// pagination from LS

function getSavedPagination() {
  const data = localStorage.getItem(LS_KEYS.PAGINATION_KEY)
  if (data && (typeof data === 'string' || typeof data === 'number')) {
    return +JSON.parse(data)
  } else {
    return 10
  }
}

// table filters

function getSavedFilter(listName: string) {
  const data = localStorage.getItem(TABLE_LIST_FILTER_KEY(listName))
  if (data && (typeof data === 'string' || typeof data === 'number')) {
    return JSON.parse(data)
  } else {
    return {}
  }
}

// balance show all

function getBalanceShowMode() {
  const data = localStorage.getItem(LS_KEYS.BALANCE_SHOW_ALL_KEY)

  if (data) {
    return data
  } else {
    return JSON.stringify(false)
  }
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function selectDebounce<T extends (...args: any[]) => any>(func: T, delay: number) {
  let timeoutId: NodeJS.Timeout
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  }
}

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  selectDebounce,
  isNotEmpty,
  getSavedPagination,
  getSavedFilter,
  getBalanceShowMode,
}
