// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {Receiver} from '../../../core/_models'

type Props = {
  row: Row<Receiver>
}

const CustomRow: FC<Props> = ({row}) => (
  <>
    {' '}
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx(
              'h-70px h-md-50px py-2 border',
              {'text-center min-w-100px': cell.column.id === 'actions'},

              {
                'text-center min-w-100px border-radius-right-def actions-td':
                  cell.column.id === 'actions',
              },
              {'border-radius-left-def': cell.column.id === 'name'}
            )}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
    <tr className='h-0'>
      <th colSpan={8} className='h-0'></th>
    </tr>
  </>
)

export {CustomRow}
