import axios from 'axios'

const API_URL = process.env.REACT_APP_API_PATH
const RECEIVERS_URL = `/receivers`
const BASE_URL = `${API_URL}${RECEIVERS_URL}`

const addPassportScan = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    // console.log('receivers addPassportScan response', data)
    return data
  } catch (err) {
    console.log(err)
  }
}

export const receiversApi = {addPassportScan}
