import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../i18n/Metronici18n'
import {establishBackendFromUrl} from '../funcs/establishOwnerFromDomain'
import {
  CountriesQueryResponse,
  ICityStreet,
  ICrauzerAddressCities,
  ICrauzerNovaPoshtaOffice,
  INovaPoshtaOffice,
} from '../tsHelpers/locationsHelpers'
import {IAddressCities} from '../tsHelpers/locationsHelpers'

// API URL ELEMENTS
axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${establishBackendFromUrl()}`

const API = process.env.REACT_APP_API_PATH

const CRAUZER_BASE = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API}`

const ADDRESS_ENDPOINT = '/address'

// cities
const getAddressCities = async (city: string, warehouse?: number, big?: number) => {
  const {selectedLang} = getConfig()

  const res = await axios.get(
    `${API}/cities?search=${city}&itemsPerPage=50`,

    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  const reformedData = res?.data?.data?.map((item: ICrauzerAddressCities) => {
    return {
      ...item,
      value: item.id,
      label: `${item.name} (${item.region.name} ${item.region.type})`,
    }
  })
  return reformedData
}
const getCityById = async (id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/city`,
    {
      id: id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// streets
const getCityStreets = async (query: string, cityId: string | number) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/streets`,
    {
      search: query,
      city: cityId,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )
  const reformedData = data.map((item: ICityStreet) => {
    return {
      value: item.ref,
      label: item.name,
    }
  })
  return reformedData
}

const getStreetById = async (id: string, city_id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/street`,
    {
      id,
      city_id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// NP offices
// types:
// 1 Parcel Shop
// 2 Поштове відділення
// 3 Поштомат приват банку
// 4 Вантажне отделение
// 5 Поштомат
const getNovaPoshtaOffices = async (
  query: string,
  cityId: string,
  vector: boolean,
  filter?: number[]
) => {
  const {selectedLang} = getConfig()

  let finalUrl: string
  let requestData: object | null = null

  const filterStringified = filter
    ? filter.length > 1
      ? filter.map((item) => `&type[]=${item}`).join('')
      : `&type=${filter}`
    : ''

  console.log(filterStringified)

  if (vector === true) {
    finalUrl = `${API}${ADDRESS_ENDPOINT}/warehouses`
    requestData = {
      search: query,
      city: cityId,
    }
  } else {
    finalUrl = `${CRAUZER_BASE}/warehouses?city=${cityId}&translations.name=${query}${filterStringified}`
  }

  const {data} = await axios.request({
    method: vector ? 'post' : 'get',
    url: finalUrl,
    data: requestData,
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  const reformedData = data.map((item: INovaPoshtaOffice | ICrauzerNovaPoshtaOffice) => {
    return typeof item === 'object' && 'big' in item
      ? {value: item.id, label: item.name, big: item.big}
      : // big: true is temporary till Yevgen adds it to the api
        {value: item.id, label: item.name, big: true}
  })

  return reformedData
}

const getWarehouseById = async (id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/warehouse`,
    {
      id: id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// countries

const fetchDirectionCountriesList = async () => {
  const {selectedLang} = getConfig()
  const {data} = await axios.get<Record<string, string>>(
    `${CRAUZER_BASE}/public/directions/countries`,
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )
  return data
}

const COUNTRIES_URL = `/country_configs`

const getAvailableCountries = (query: string): Promise<CountriesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${CRAUZER_BASE}${COUNTRIES_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<CountriesQueryResponse>) => d.data)
}

export const locationsApi = {
  getAddressCities,
  getCityStreets,
  getNovaPoshtaOffices,
  fetchDirectionCountriesList,
  getCityById,
  getWarehouseById,
  getStreetById,
  getAvailableCountries,
}
