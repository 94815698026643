/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, ErrorMessage, useFormikContext} from 'formik'
import InputMask from 'react-input-mask'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'

type TProps = {
  inputName?: string
  value?: string
  noError?: boolean
}
type DynamicValues = Partial<ICrauzerUser> & {[key: string]: any}

function ProfileNumberItem({inputName = 'phoneNumber', value, noError = false}: TProps) {
  const {setFieldValue, values} = useFormikContext<DynamicValues>()

  return (
    <div className='position-relative p-0 w-100'>
      <Field name={inputName}>
        {() => (
          <InputMask
            name={inputName}
            type='tel'
            maskChar={null}
            mask='+38 (099) 999-99-99'
            className={`form-control form-control-lg text-dark fs-5 input_general input-border`}
            value={value || values[inputName]}
            onChange={(e) => {
              setFieldValue(inputName, e.target.value.replace(/[^0-9+]/g, ''))
            }}
          />
        )}
      </Field>

      {!noError && (
        <div className='text-danger'>
          <ErrorMessage name={inputName} />
        </div>
      )}
    </div>
  )
}

export {ProfileNumberItem}
