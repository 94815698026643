import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {TOneOfOrders} from '../../../../../../../pages/orders/_models'
import {deleteOrder} from '../../../../../../../pages/orders/_requests'
import {orderTypeguards} from '../../../../../../../pages/orders/_typeguards'
import {parcelTypeguards} from '../../../../../../../pages/parcels/core/_typeguards'

type Props = {
  order: TOneOfOrders
}

const Actions = ({order}: Props) => {
  const {id} = order
  const [modalOpen, setModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteOrder(id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const actions = [
    {
      icon: '/media/company-specific/pokupka/time.svg',
      text: 'час',
      link: null,
      show: parcelTypeguards.isIParcelCode(order) ? false : true,
    },
    {
      icon: '/media/company-specific/pokupka/warning.svg',
      text: 'попередження',
      link: null,
      show: true,
    },
    {
      icon: '/media/company-specific/pokupka/edit.svg',
      text: 'редагувати',
      link: `/orders/buyouts/${id}`,
      show: orderTypeguards.isIOrderType(order) && order.type === 'buyout' ? !order.paid : true,
    },
    {
      icon: '/media/company-specific/pokupka/trash-can.svg',
      text: 'DELETE',
      link: null,
      func: deleteMutation,
      show: orderTypeguards.isIOrderType(order) && order.type === 'buyout',
    },
  ]

  return (
    <>
      {actions
        .filter((i) => i.show)
        .map((item, index) =>
          item.link ? (
            <Link
              to={item.link}
              key={index}
              className={`action-container d-flex align-items-center justify-content-center py-2 ${
                item.icon ? 'px-7' : 'px-4'
              }`}
            >
              {' '}
              {item.icon ? (
                <KTSVG
                  path={item.icon}
                  className={`svg-icon-4 svg-icon-custom-grey-light svg-icon-custom-hover-additional cursor-pointer`}
                />
              ) : (
                <span className='ms-3 ms-lg-5 fw-normal text-decoration-underline text-hover-additional text-center cursor-pointer'>
                  <FormattedMessage id={item.text} />
                </span>
              )}
            </Link>
          ) : (
            <div
              key={index}
              className={`action-container d-flex align-items-center justify-content-center py-2 ${
                item.icon ? 'px-7' : 'px-4'
              }`}
            >
              {item.icon ? (
                <KTSVG
                  path={item.icon}
                  className={`svg-icon-4 svg-icon-custom-grey-light svg-icon-custom-hover-additional cursor-pointer`}
                />
              ) : (
                <span className='ms-3 ms-lg-5 fw-normal text-decoration-underline text-hover-additional text-center cursor-pointer'>
                  <FormattedMessage id={item.text} />
                </span>
              )}
            </div>
          )
        )}
    </>
  )
}

export default Actions
