/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../modules/custom/modals/ActionConfirmation'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {Receiver} from '../../../../core/_models'
import {deleteReceiver} from '../../../../core/_requests'

type Props = {
  receiver: Receiver
}

const ReceiverActionsCell: FC<Props> = ({receiver}) => {
  const {id} = receiver
  const [modalOpen, setModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      // return await deleteOrder(id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const actions = [
    // {text: '', link: null},
    {
      icon: '/media/company-specific/pokupka/edit.svg',
      text: 'редагувати',
      link: `/receivers/edit/content/${id}`,
    },
    {
      icon: null,
      text: 'DELETE',
      link: null,
      func: deleteMutation,
    },
  ]

  return (
    <div className='d-flex justify-content-end flex-shrink-0'>
      {actions.map((item, index) => (
        <div
          key={index}
          className={`action-container d-flex align-items-center justify-content-center py-2 ${
            item.icon ? 'px-7' : 'px-4'
          }`}
        >
          {item.icon ? (
            <KTSVG
              path={item.icon}
              className={`svg-icon-4 svg-icon-custom-grey-light svg-icon-custom-hover-additional cursor-pointer`}
            />
          ) : item.text ? (
            <span className='ms-3 ms-lg-5 fw-normal text-decoration-underline text-hover-additional text-center cursor-pointer'>
              <FormattedMessage id={item.text} />
            </span>
          ) : (
            <span></span>
          )}
        </div>
      ))}
    </div>
  )
}

export {ReceiverActionsCell}
