import {useQueries, useQueryClient} from 'react-query'
import {Route, Routes, Outlet, Navigate, useParams} from 'react-router-dom'
import {KTCard, QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from '../../../_metronic/helpers/custom/api/directionsApi'
import {locationsApi} from '../../../_metronic/helpers/custom/api/locationsApi'
import {BuyoutsListWrapper} from './buyouts/buyouts-list/BuyoutsListWrapper'
import {CreateBuyout} from './buyouts/_modals/CreateBuyout'
import {TracksListWrapper} from './tracks/tracks-list/TracksListWrapper'
import {transformDirectionsForForm} from '../../modules/custom/sections/orders/OrdersList'
import {usePageData} from '../../../_metronic/layout/core'
import BuyoutPage from './buyouts/buyout-page/BuyoutPage'
const OrdersPage = () => {
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const [{data: directions}] = useQueries([
    {
      queryKey: [QUERIES.DIRECTIONS_LIST],
      queryFn: () => directionsApi.fetchDirectionsWithIds(countries),
    },
  ])

  const {pageCustomData} = usePageData()
  const params = useParams()
  // const loc = useLocation()

  // const getPaymentStatus = async (sessionId: string) => {
  //   const res = await paymentIntents.checkPaymentStatus(sessionId)
  // }

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(loc.search)
  //   const sessionId = queryParams.get('session_id')
  //   if (sessionId) {
  //     getPaymentStatus(sessionId)
  //   }
  // }, [])

  return (
    <Routes>
      <Route
        element={
          <KTCard className='list-card'>
            <Outlet />
          </KTCard>
        }
      >
        {/* <Route
          path={pathnameValue.buyoutPagePath}
          element={
            <BuyoutPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        /> */}

        <Route path='/buyouts' element={<BuyoutsListWrapper orderType='buyout' />} />
        {countries && directions && (
          <>
            <Route
              path='/buyouts/new'
              element={
                <CreateBuyout directions={transformDirectionsForForm(directions, countries)} />
              }
            />
            <Route path='/buyouts/:id' element={<BuyoutPage />} />
          </>
        )}

        <Route path='/tracks' element={<TracksListWrapper orderType='track' />} />
        <Route path='/tracks/new' element={<TracksListWrapper orderType='track' />} />
      </Route>
      <Route index element={<Navigate to={'/orders/buyouts'} />} />
    </Routes>
  )
}

export default OrdersPage
