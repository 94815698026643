import {ListViewProvider} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../_metronic/helpers'

import {useEffect} from 'react'
import {TransactionsListHeader} from './components/header/TransactionsListHeader'

const TransactionsList = () => {
  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.TRANSACTIONS_LIST)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.TRANSACTIONS_LIST),
      JSON.stringify(savedFilter)
    )
  }, [])

  return (
    <KTCard>
      Транзакції
      <TransactionsListHeader />
      {/*  <TransactionsTable /> */}
    </KTCard>
  )
}

const TransactionsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TransactionsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TransactionsListWrapper}
