/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate} from 'react-intl'
import {IOrderProps} from '../../../../../../../../pages/orders/_models'
import {Country} from '../../modules'

const DateCell = ({order}: IOrderProps) => {
  return (
    <div className='mw-100px text-start text-lg-center fw-normal text-dark d-flex flex-column flex-col-gutter'>
      <div className='d-md-none fs-2 fs-md-5'>
        <Country order={order} />
      </div>{' '}
      {order.createdAt && (
        <FormattedDate year='2-digit' month='numeric' day='numeric' value={order.createdAt} />
      )}
    </div>
  )
}

export {DateCell}
