import {FormattedMessage} from 'react-intl'
import {orderTypeguards} from '../../../../../../../pages/orders/_typeguards'
import {parcelTypeguards} from '../../../../../../../pages/parcels/core/_typeguards'
import {OrderRowProps} from '../columns/row/Details'

const StatusAndTtn = ({row}: OrderRowProps) => {
  const number = orderTypeguards.isIOrderNumber(row.original)
    ? row.original.number
    : parcelTypeguards.isIParcelCode(row.original)
    ? row.original.code
    : ''
  return (
    <div className='row flex-column flex-col-gutter flex-md-row flex-fill no-borders fs-5 pt-3'>
      {/* {row.original.type !== 'buyout' && ( */}
      <div className='row subrow fw-normal d-md-none'>
        <div className='col pe-0'>
          {' '}
          <FormattedMessage id={'ORDERS.WAYBILL'} />
        </div>
        <div className='col ps-0'>
          {' '}
          <span className='text-body'>{number}</span>
        </div>
      </div>
      {/* )} */}
      <div className='row subrow col-md-8 fw-normal mb-4'>
        <div className='col col-md-6 pe-0'>
          <FormattedMessage id={'ORDERS.STATUS'} />
        </div>
        <div className='col col-md-6 ps-0'>
          {' '}
          <span className='text-body'>{number}</span>
        </div>
      </div>
    </div>
  )
}

export default StatusAndTtn
