import {useListView as useListViewTracks} from '../../../../../../../pages/orders/tracks/core/ListViewProvider'
import {useListView as useListViewBuyouts} from '../../../../../../../pages/orders/buyouts/core/ListViewProvider'

import {OrdersListToolbar} from './OrdersListToolbar'
import {useLocation} from 'react-router-dom'
import {useListView as useListViewParcels} from '../../../../../../../pages/parcels/core/ListViewProvider'
import {OrdersListGrouping} from './OrdersListGrouping'

const OrdersListHeader = () => {
  const {pathname} = useLocation()
  const tListView = useListViewTracks()
  const bListView = useListViewBuyouts()
  const pListView = useListViewParcels()

  const {selected} = pathname.includes('buyouts')
    ? bListView
    : pathname.includes('tracks')
    ? tListView
    : pListView
  return (
    <div className='card-header border-0 justify-content-end shadow-pokupka p-5'>
      {/* <h3>
          <FormattedMessage
            id={isArchive === true ? 'BUYOUTS.PAGE.PAGE_HEADING_ARCH' : 'BUYOUTS.PAGE.PAGE_HEADING'}
          />
        </h3> */}
      {!pathname.includes('new') && (
        <div className='card-toolbar my-0'>
          {/* begin::Group actions */}
          {selected.length > 0 ? <OrdersListGrouping /> : <OrdersListToolbar />}
        </div>
      )}

      {/* <button
        type='button'
        className='d-none d-md-flex btn btn-clean btn-shadow shadow-pokupka px-2 text-custom-accent fw-bold with-icon'
        onClick={() => setItemIdForUpdate(null)}
      >
        <KTSVG
          path='/media/company-specific/pokupka/cart.svg'
          className='svg-icon-4 icon svg-icon-custom-white d-flex align-items-center justify-content-center'
          svgClassName='svg'
        />
        <span className='px-4'>
          <FormattedMessage id='BUYOUTS.LIST.ADD_BUTTON' />
        </span>
      </button> */}
    </div>
  )
}

export {OrdersListHeader}
