/* eslint-disable jsx-a11y/anchor-is-valid */
import {statusColor} from '../../../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {IOrderProps} from '../../../../../../../../pages/orders/_models'
import TotalSum from '../../modules/TotalSum'

const CostCell = ({order}: IOrderProps) => {
  return (
    <div>
      <TotalSum order={order} />
      {order.type === 'buyout' && order.charges?.some((i) => !i.paid) && (
        <div>
          {' '}
          <span
            className={`px-1 fs-7 fw-semobold  StatusBar__status cursor-pointer ${statusColor(
              false
            )}`}
          >
            {order.charges?.filter((i) => !i.paid).reduce((acc, i) => acc + i.amount, 0)}{' '}
            {order.charges[0].currency}
          </span>
        </div>
      )}
    </div>
  )
}

export {CostCell}
