import {FormattedMessage, useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {getPaymentOptions} from '../../../../../app/pages/balance/core/_requests'
import {getWarehouseData} from '../../../../../app/pages/warehouse/core/_requests'
import {QUERIES} from '../../../../helpers'
import {locationsApi} from '../../../../helpers/custom/api/locationsApi'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
  useQuery(QUERIES.COUNTRY_ABBR_LIST, locationsApi.fetchDirectionCountriesList)
  useQuery('warehouseAddress', getWarehouseData)

  return (
    <>
      {menuConfig.aside.items.map((item, index) =>
        item.submenu ? (
          <SidebarMenuItemWithSub
            to={item.page}
            title={intl.formatMessage({id: item.translate})}
            icon={`/media/company-specific/pokupka/sidebar-icons/${item.icon}.svg`}
            fontIcon='bi-layers'
            colorTheme={item.colorTheme}
            key={index}
          >
            {item.submenu.map((subItem, index) => (
              <SidebarMenuItem
                to={`/${item.page}/${subItem.page}`}
                title={intl.formatMessage({id: subItem.translate})}
                hasBullet={true}
                key={index}
                colorTheme={subItem.colorTheme}
              />
            ))}
          </SidebarMenuItemWithSub>
        ) : (
          <SidebarMenuItem
            to={`/${item.page}`}
            title={intl.formatMessage({id: item.translate})}
            icon={`/media/company-specific/pokupka/sidebar-icons/${item.icon}.svg`}
            colorTheme={item.colorTheme}
            fontIcon='bi-app-indicator'
            key={index}
          />
        )
      )}

      {/* <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'DASHBOARD.PAGE.PAGE_TITLE'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/profile'
        icon='/media/icons/duotune/communication/com013.svg'
        title={intl.formatMessage({id: 'SIDEBAR.PROFILE_TITLE'})}
        fontIcon='bi-layers'
      />
      {process.env.REACT_APP_URL === 'logistic.ndv.crauzer.com' ? (
        <SidebarMenuItem
          to='/fulfillment/receivers'
          icon='/media/icons/duotune/communication/com014.svg'
          title={intl.formatMessage({id: 'RECEIVERS.PAGE.PAGE_TITLE'})}
          fontIcon='bi-layers'
        />
      ) : (
        <SidebarMenuItem
          to='/receivers'
          icon='/media/icons/duotune/communication/com014.svg'
          title={intl.formatMessage({id: 'RECEIVERS.PAGE.PAGE_TITLE'})}
          fontIcon='bi-layers'
        />
      )}
      <SidebarMenuItem
        to='/news'
        icon='/media/icons/duotune/general/gen045.svg'
        title={intl.formatMessage({id: 'NEWS.PAGE.PAGE_TITLE'})}
        fontIcon='bi-layers'
      />
<<<<<<< HEAD
      <SidebarMenuItem
        to='/warehouse'
        icon='/media/icons/duotune/maps/map008.svg'
        title={intl.formatMessage({id: 'WAREHOUSE.PAGE.PAGE_TITLE'})}
        fontIcon='bi-layers'
      />
=======
      {whAddressesData?.some(
        (item) =>
          (item.addressHidden === false && item.addressData.length > 0) ||
          (item.secondaryAddressHidden === false && item.secondaryAddressData?.length > 0)
      ) && (
        <SidebarMenuItem
          to='/warehouse'
          icon='/media/icons/duotune/maps/map008.svg'
          title={intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.PAGE_TITLE'})}
          fontIcon='bi-layers'
        />
      )}
>>>>>>> new
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <FormattedMessage id='SIDEBAR.BUYOUTS_TITLE' />
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/buyouts'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.LIST'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
      />
      <SidebarMenuItem
        to='/buyouts/create'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.ADD'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen041.svg'
      />
      <SidebarMenuItem
        to='/archived-buyouts'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.TRASH'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen027.svg'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <FormattedMessage id='TRACKS.PAGE.PAGE_TITLE' />
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/tracks/create'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.ADD'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen041.svg'
      />{' '}
      <SidebarMenuItem
        to='/tracks/list/air'
        title={intl.formatMessage({id: 'SIDEBAR.TRACKS.AIR'})}
        fontIcon='bi-archive'
        icon='/media/icons/flight.svg'
      />
      <SidebarMenuItem
        to='/tracks/list/sea'
        title={intl.formatMessage({id: 'SIDEBAR.TRACKS.SEA'})}
        fontIcon='bi-archive'
        icon='/media/icons/anchor.svg'
      />
      <SidebarMenuItem
        to='/tracks/list/archive'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.TRASH'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen027.svg'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <FormattedMessage id='PARCELS.PAGE.PAGE_TITLE' />
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/parcels/list/air'
        title={intl.formatMessage({id: 'SIDEBAR.TRACKS.AIR'})}
        fontIcon='bi-archive'
        icon='/media/icons/flight.svg'
      />{' '}
      <SidebarMenuItem
        to='/parcels/list/sea'
        title={intl.formatMessage({id: 'SIDEBAR.TRACKS.SEA'})}
        fontIcon='bi-archive'
        icon='/media/icons/anchor.svg'
      />{' '}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Фулфілмент</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/fulfillment/goods'
        title='Товари'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
      /> */}
    </>
  )
}

export {SidebarMenuMain}

const menuConfig = {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      {
        title: 'Profile',
        root: true,
        icon: 'icon1',
        colorTheme: 'frame1',
        'custom-class': 'pos1',
        page: 'profile',
        translate: 'SIDEBAR.PROFILE_TITLE',
        bullet: 'dot',
      },
      {
        title: 'Receivers',
        root: true,
        icon: 'icon2',
        colorTheme: 'frame2',
        'custom-class': 'pos2',
        page: 'receivers',
        translate: 'RECEIVERS.PAGE.PAGE_TITLE',
        bullet: 'dot',
      },
      {
        title: 'Orders',
        root: true,
        icon: 'icon3',
        colorTheme: 'frame3',
        'custom-class': 'pos3',
        page: 'orders',
        'dropdown-toggle-class': 'click',
        translate: 'MENU.ORDERS',
        bullet: 'dot',
        submenu: [
          {
            title: 'Buyout',
            'custom-class': 'sub1',
            page: 'buyouts', //buyout
            translate: 'MENU.ORDER',
            bullet: 'dot',
            colorTheme: 'additional',
          },
          {
            title: 'Virtual address',
            'custom-class': 'sub2',
            page: 'tracks', //track
            translate: 'MENU.TRACK',
            bullet: 'dot',
            colorTheme: 'accent',
          },
        ],
      },
      {
        title: 'Package',
        root: true,
        icon: 'icon4',
        colorTheme: 'frame4',
        'custom-class': 'pos4',
        page: 'parcels',
        translate: 'MENU.PACKAGES',
        bullet: 'dot',
      },
      {
        title: 'Finances',
        root: true,
        icon: 'icon5',
        colorTheme: 'frame5',
        'custom-class': 'pos5',
        page: 'finances/charges',
        translate: 'MENU.FINANCES',
        bullet: 'dot',
      },
      // {
      //   title: 'Messages',
      //   root: true,
      //   icon: 'icon6',
      //   colorTheme: 'frame6',
      //   'custom-class': 'pos6',
      //   page: '#',
      //   translate: 'MENU.MESSAGES',
      //   bullet: 'dot',
      //   badge: {
      //     type: 'danger',
      //     value: 1,
      //   },
      // },
      // {
      //   title: 'Notifications',
      //   root: true,
      //   icon: 'icon7',
      //   colorTheme: 'frame7',
      //   'custom-class': 'pos7',
      //   page: '#',
      //   translate: 'MENU.NOTIFICATIONS',
      //   bullet: 'dot',
      //   badge: {
      //     type: 'danger',
      //     value: 2,
      //   },
      // },
      {
        title: 'Stats',
        root: true,
        icon: 'icon8',
        colorTheme: 'frame8',
        'custom-class': 'pos8',
        page: '#',
        translate: 'MENU.STATS',
        bullet: 'dot',
      },
      {
        title: 'Storage',
        root: true,
        icon: 'icon9',
        colorTheme: 'frame9',
        'custom-class': 'pos9',
        page: 'warehouse',
        translate: 'WAREHOUSE.PAGE.PAGE_TITLE',
        bullet: 'dot',
      },
      // {
      //   title: 'Help',
      //   root: true,
      //   icon: 'icon10',
      //   colorTheme: 'frame10',
      //   'custom-class': 'pos10',
      //   page: 'help',
      //   translate: 'MENU.HELP',
      //   bullet: 'dot',
      // },
    ],
  },
}
