import Weight from '../../modules/Weight'
import TotalSum from '../../modules/TotalSum'
import {IOrderProps} from '../../../../../../../../pages/orders/_models'

const WeightCell = ({order}: IOrderProps) => {
  const {products, weight} = order

  return (
    <div className='text-center text-md-start'>
      <Weight order={order} />{' '}
      <div className='d-md-block d-lg-none'>
        {weight && products?.length > 0 && <span> / </span>}
        <TotalSum order={order} />
      </div>
    </div>
  )
}

export {WeightCell}
