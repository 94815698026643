import React, {useState} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {Link} from 'react-router-dom'

type Props = {
  to: string
  title: string
  icon?: string
  colorTheme?: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  colorTheme,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <div className={clsx('menu-item', 'menu-accordion')} data-kt-menu-trigger='click'>
      <span
        className={clsx(`menu-link p-0 pe-5 bg-${colorTheme}-hover`, {
          active: isActive,
          [`bg-${colorTheme}-active`]: isActive,
        })}
      >
        {hasBullet ? (
          <span className='menu-bullet'>
            <span className={clsx(`bullet bullet-dot text-custom-${colorTheme}`)}></span>
          </span>
        ) : (
          <KTSVG
            path={`/media/company-specific/pokupka/frames/${colorTheme}.svg`}
            svgClassName='h-40px w-0'
          />
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-6' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className={clsx('menu-title text-custom-grey')}>{title}</span>{' '}
        <span className='menu-arrow' onClick={toggleDropdown}></span>
      </span>

      <div
        className={clsx(
          'menu-sub w-100 menu-sub-accordion mx-0 py-3',
          {'menu-active-bg': isDropdownOpen},
          'shadow-pokupka-inverted '
        )}
      >
        {children}
      </div>
    </div>
  )
}

export {SidebarMenuItemWithSub}
