import {useIntl} from 'react-intl'
import {IOneOfOrders} from '../../../../../../../pages/orders/_models'
import {orderTypeguards} from '../../../../../../../pages/orders/_typeguards'

const Weight = ({order}: IOneOfOrders) => {
  const intl = useIntl()
  const {weight} = order

  const weightUnit = orderTypeguards.isIOrderDirection(order) ? order?.direction?.weightUnit : 'kg'
  return (
    <span className='fs-6 text-dark fw-normal'>
      {!!weight &&
        `${weight} ${intl.formatMessage({
          id: weightUnit?.toUpperCase(),
        })}`}
    </span>
  )
}

export default Weight
