/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../_metronic/helpers'
import {useQueryClient} from 'react-query'

import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'
import {Direction} from '../../../../../_metronic/helpers/custom/tsHelpers/directionsHelpers'
import {useQueryRequest as useQueryRequestTracks} from '../../../../pages/orders/tracks/core/QueryRequestProvider'
import {useQueryRequest as useQueryRequestBuyouts} from '../../../../pages/orders/buyouts/core/QueryRequestProvider'
import {useQueryRequest as useQueryRequestParcels} from '../../../../pages/parcels/core/QueryRequestProvider'
import {OrdersTable} from './list/table/OrdersTable'

export const transformDirectionsForForm = (arr: Direction[], countries: Record<string, string>) => {
  return arr?.map((item) => {
    return {
      ...item,
      label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries),
      value: item.id,
    }
  })
}
export type OrderTypes = 'buyout' | 'track' | 'parcel'

export type OrderTypesProps = {
  orderType: OrderTypes
}

export const OrdersList = ({orderType}: OrderTypesProps) => {
  const isBuyout = orderType === 'buyout'
  const isTrack = orderType === 'track'

  const tQueryRequest = useQueryRequestTracks()
  const bQueryRequest = useQueryRequestBuyouts()
  const pQueryRequest = useQueryRequestParcels()

  const queryRequest = isBuyout ? bQueryRequest : isTrack ? tQueryRequest : pQueryRequest

  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const directions: Direction[] = queryClient.getQueryData([QUERIES.DIRECTIONS_LIST]) || []

  const {updateState} = queryRequest

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(
      isBuyout ? QUERIES.ORDERS_LIST : isTrack ? QUERIES.TRACKS_LIST : QUERIES.PARCELS_LIST
    )

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    if (isBuyout) {
      state.filter.type = 'buyout'
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(
        isBuyout ? QUERIES.ORDERS_LIST : isTrack ? QUERIES.TRACKS_LIST : QUERIES.PARCELS_LIST
      ),
      JSON.stringify(savedFilter)
    )
  }, [])

  return <OrdersTable orderType={orderType} />
}
