import Moment from 'react-moment'
import {IOneOfOrders} from '../../../../../../../pages/orders/_models'

type TProps = {full?: boolean}

const Logs = ({order, full = false}: IOneOfOrders & TProps) => {
  return (
    <table className='table-responsive table-logs w-100'>
      <tr className={`order-log d-flex w-100 h-25px`}>
        {' '}
        {full && (
          <>
            <td className='log-date text-custom-grey-light pe-7'>
              <Moment format='DD.MM.YY HH:mm'>{new Date()}</Moment>
            </td>{' '}
            <td className='log-point border border-left-0 border-top-0 border-bottom-0 border-right-1'></td>
          </>
        )}
        <td className={`log-text mb-1 fw-normal ${full ? 'ps-7 w-50' : 'ms-n3 h-25px'}`}>log 1</td>
      </tr>{' '}
      <tr className={`order-log d-flex w-100 h-25px`}>
        {' '}
        {full && (
          <>
            <td className='log-date text-custom-grey-light pe-7'>
              <Moment format='DD.MM.YY HH:mm'>{new Date()}</Moment>
            </td>
            <td className='log-point border border-left-0 border-top-0 border-bottom-0 border-right-1'></td>
          </>
        )}{' '}
        <td className={`log-text mb-1 fw-normal ${full ? 'ps-7 w-50' : 'ms-n3 h-25px'}`}>log 2</td>
      </tr>
      <tr className={`order-log d-flex w-100 h-25px`}>
        {' '}
        {full && (
          <>
            <td className='log-date text-custom-grey-light pe-7'>
              <Moment format='DD.MM.YY HH:mm'>{new Date()}</Moment>
            </td>
            <td className='log-point border border-left-0 border-top-0 border-bottom-0 border-right-1'></td>
          </>
        )}{' '}
        <td className={`log-text mb-1 fw-normal ${full ? 'ps-7 w-50' : 'ms-n3 h-25px'}`}>log 3</td>
      </tr>
    </table>
  )
}
export default Logs
