import {FormattedMessage} from 'react-intl'
import {IParcelOrderProps} from '../core/_models'

const ShortInfo = ({parcel}: IParcelOrderProps) => {
  return (
    <div className='d-flex flex-column flex-col-gutter fs-3'>
      <div className='d-flex justify-content-between'>
        <span>
          <FormattedMessage id='PARCEL.PAGE.SERVICE_LABEL' />
        </span>
        <span className='text-dark'>Викуп</span>
      </div>
      <div className='d-flex justify-content-between'>
        <span>
          <FormattedMessage id='PARCEL.PAGE.WEIGHT_LABEL' />
        </span>
        <span className='text-dark'>{parcel.weight}</span>
      </div>
      <div className='d-flex justify-content-between'>
        <span>
          <FormattedMessage id='PARCEL.PAGE.COST_LABEL' />
        </span>
        <span className='text-dark'>
          {parcel.parcelProducts.reduce((acc, i) => acc + i.priceForAll, 0)}
        </span>
      </div>
    </div>
  )
}

export default ShortInfo
