import {useQueries} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from '../../../_metronic/helpers/custom/api/directionsApi'
import {locationsApi} from '../../../_metronic/helpers/custom/api/locationsApi'
import PaymentFail from './PaymentFail'
import PaymentSuccess from './PaymentSuccess'

const PaymentsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path={`fail`} element={<PaymentFail />} />
        <Route path={`success`} element={<PaymentSuccess />} />
      </Route>
    </Routes>
  )
}

export default PaymentsPage
