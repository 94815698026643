import {useQueries, useQueryClient} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from '../../../_metronic/helpers/custom/api/directionsApi'
import {locationsApi} from '../../../_metronic/helpers/custom/api/locationsApi'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CreateReceiver} from './receiver-add/CreateReceiver'
import {ReceiversListWrapper} from './receivers-list/ReceiversList'

const ReceiversPage = () => {
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  useQueries([
    {
      queryKey: [QUERIES.COUNTRIES_LIST],
      queryFn: () => locationsApi.getAvailableCountries(''),
    },
    {
      queryKey: [QUERIES.DIRECTIONS_LIST],
      queryFn: () => directionsApi.fetchDirectionsWithIds(countries),
    },
  ])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<ReceiversListWrapper />} />

        <Route
          path={`new`}
          element={
            <QueryRequestProvider>
              <QueryResponseProvider>
                <ListViewProvider>
                  <CreateReceiver />
                </ListViewProvider>
              </QueryResponseProvider>
            </QueryRequestProvider>
          }
        />
      </Route>
    </Routes>
  )
}

export default ReceiversPage
