/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../../../_metronic/helpers/custom/funcs/directions'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import FlagsDirectionItem from '../../../../../../modules/custom/FlagsDirectionItem'
import {ICharge} from '../../../../core/_models'

type Props = {
  charge: ICharge
}

const DirectionCell = ({charge}: Props) => {
  const queryClient = useQueryClient()
  const countriesList: Record<string, string> | undefined = queryClient.getQueryData(
    QUERIES.COUNTRY_ABBR_LIST
  )

  return (
    <div className='d-flex align-items-center justify-content-center'>
      {charge.direction && countriesList && (
        <div className={`FlagsDirectionBar me-2 px-0 mb-0`}>
          <FlagsDirectionItem
            from={charge.direction?.countryFrom}
            to={charge.direction?.countryTo}
            minified={true}
          />
          <p className='fw-normal text-dark mb-0'>
            {directionsFuncs.formDirection(
              charge.direction?.countryFrom,
              charge.direction?.countryTo,
              countriesList
            )}
          </p>
        </div>
      )}
    </div>
  )
}

export {DirectionCell}
