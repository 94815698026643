/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage, useIntl} from 'react-intl'
import {ICharge} from '../../../../core/_models'

type Props = {
  charge: ICharge
}

const SumCell = ({charge}: Props) => {
  const intl = useIntl()
  return <div className='fw-normal text-dark mb-2'>{`${charge?.amount} ${charge?.currency}`}</div>
}

export {SumCell}
