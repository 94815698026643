import {CellImage} from '../../../../../../../../modules/custom/lists/CellImage'
import {IOrderProps} from '../../../../../../../../pages/orders/_models'
import {orderTypeguards} from '../../../../../../../../pages/orders/_typeguards'

const LogoCell = ({order}: IOrderProps) => {
  const logoUrl = orderTypeguards.isIOrderWebsiteShop(order)
    ? order.websiteShop?.logoUrl
    : undefined

  return (
    <CellImage
      itemNumber={order.number}
      defaultUrl={
        logoUrl
          ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${logoUrl}`
          : '/media/icons/duotune/ecommerce/ecm005.svg'
      }
      tooltip={order.websiteShop?.name}
    />
  )
}

export {LogoCell}
