const QUERIES = {
  ARCHIVED_BUYOUTS_LIST: 'archived-buyouts-list',
  BALANCE_CURRENT: 'balance-current',
  BALANCE_LIST: 'balance-list',
  DASHBOARD_INFO: 'dashboard-info',
  FULF_REC_ADDRESS_LIST: 'fulfReceiverAddresses',
  PAYMENT_OPTIONS: 'payment-options',
  REC_ADDRESS_LIST: 'receiverAddresses',
  WAREHOUSES_LIST: 'warehouseAddresses',
  ARCHIVED_TRACKS_LIST: 'archived-tracks-list',
  BLOG_ARTICLES_LIST: 'blog-articles-list',
  BUYOUTS_LIST: 'buyouts-list',
  CATEGORIES_LIST: 'categories-list',
  CHARGES_LIST: 'charges-list',
  CHATS_LIST: 'chats-list',
  CHATS_STATUSES_LIST: 'chats-statuses-list',
  CLIENT_TRANSLATION: 'client-translation',
  COUNTRIES_LIST: 'countries-list',
  COUNTRY_ABBR_LIST: 'country-abbr-list',
  COURIERS_LIST: 'couriers-list',
  COURIER_TYPES: 'courier-types',
  DELIVERY_TYPES_LIST: 'delivery-types-list',
  DIRECTIONS_LIST: 'directions-list',
  DIRECTIONS_WITH_IDS_LIST: 'directionsWithIds',
  EMAIL_TEMPLATES_LIST: 'email-templates-list',
  EMPLOYEES_LIST: 'employees-list',
  FULF_RECEIVERS_LIST: 'fulf-receivers-list',
  LOCALES_LIST: 'locales-list',
  LOCATIONS_LIST: 'locations-list',
  ORDERS_LIST: 'orders-list',
  PARCELS_LIST: 'parcels-list',
  PRODUCTS_LIST: 'fulfillment-products-list',
  PROFILE_ADDRESSES_LIST: 'profile-addresses-list',
  RECEIVER_ADDRESSES: 'receiver-addresses-list',
  RECEIVER_HISTORY: 'receiver-history',
  RECEIVERS_LIST: 'receivers-list',
  RECENT_INVOICES: 'recent-invoices-list',
  REGIONS_LIST: 'regions-list',
  SHIPMENTS_LIST: 'shipments-list',
  STORES_LIST: 'stores-list',
  TARIFFS_LIST: 'tariffs-list',
  TRACKS_ACCEPT_LIST: 'accept-tracks-list',
  TRACKS_LIST: 'tracks-list',
  TRANSACTIONS_LIST: 'transactions-list',
  TRANSLATIONS_CHAT_LIST: 'chat-tr-list',
  TRANSLATIONS_CLIENT_LIST: 'client-tr-list',
  TRANSLATIONS_TELEGRAM_LIST: 'telegram-tr-list',
  USER_GROUPS_LIST: 'user-groups-list',
  USER_HISTORY: 'user-history',
  USERS_LIST: 'users-list',
  WEBSITE_CATEGORIES_LIST: 'website-categories-list',
  WEBSITES_LIST: 'websites-list',
  // BY ID
  COMPANY_DETAILS: 'company-details',
  COUNTRY_BY_ID: 'country-by-id',
  COURIER_BY_ID: 'courier-by-id',
  DIRECTION_BY_ID: 'direction-by-id',
  LOCATION_BY_ID: 'location-by-id', //city
  ORDER_BY_ID: 'order-by-id',
  PARCEL_BY_ID: 'parcel-by-id',
  RECEIVER_BY_ID: 'receiver-by-id',
  REGION_BY_ID: 'region-by-id',
  SHIPMENT_BY_ID: 'shipment-by-id',
  TRACK_BY_ID: 'track-by-id',
  TELEGRAM_TR_BY_ID: 'chat-translation',
  USER_BY_ID: 'user-by-id',
  WAREHOUSE_BY_ID: 'warehouse-by-id',
  // UNITS
  CURRENCIES_LIST: 'currencies-list',
  SIZE_UNITS: 'sizeUnits',
  VOL_WEIGHT_OPTIONS: 'volumetricWeightOptions',
  WEIGHT_UNITS: 'weightUnits',
}

const LS_KEYS = {
  PAGINATION_KEY: 'tablePagination',
  BALANCE_SHOW_ALL_KEY: 'balanceShowAll',
}

const TABLE_LIST_FILTER_KEY = (listName: string) => {
  return `${listName}-filter`
}

const FULF_RECEIVER_VERIFICATION_STATUS = {
  VERIFIED: 'Verified', // success
  CHECKED: 'Checked', // danger
  WAITING: 'Waiting', // warning
}

const GTM_SCRIPTS = (id: string) => {
  return {
    dateTime: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`,

    iframe: `<iframe src='https://www.googletagmanager.com/ns.html?id=${id}'height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
  }
}

const CARDFOOTER_NOTIFICATIONS = (msg?: string) => {
  return {
    SUCCESSFULLY_SAVED: {
      success: true,
      color: 'success',
      intl: 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING',
      icon: '/media/icons/duotune/general/gen043.svg',
    },
    ERROR: {
      success: true,
      color: 'danger',
      intl: msg || '',
      icon: '/media/icons/duotune/general/gen044.svg',
    },
  }
}
export {
  QUERIES,
  LS_KEYS,
  GTM_SCRIPTS,
  FULF_RECEIVER_VERIFICATION_STATUS,
  TABLE_LIST_FILTER_KEY,
  CARDFOOTER_NOTIFICATIONS,
}
