import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../_metronic/helpers'

import {IStore} from '../../../_metronic/helpers/custom/tsHelpers/storeModels'
import {getConfig} from '../../../_metronic/i18n/Metronici18n'
import {ICreateNewOrder, IOrder, OrdersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const ORDERS_URL = `/orders`
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${ORDERS_URL}`

const getOrders = (query: string): Promise<OrdersQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<OrdersQueryResponse>) => d.data)
}

const getOrderById = async (id: ID): Promise<IOrder | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const deleteOrder = async (id: ID) => {
  const {selectedLang} = getConfig()

  const res = await axios.get(`${BASE_URL}/delete`, {
    params: {id},
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  return res
}

const addOrder = (order: Partial<ICreateNewOrder>): Promise<IOrder | undefined> => {
  return axios
    .post(`${BASE_URL}`, order)
    .then((response: Response<IOrder | undefined>) => response.data)
    .then((response: IOrder | undefined) => {
      console.log(response)
      return response
    })
}

const editOrder = (order: Partial<ICreateNewOrder>): Promise<IOrder | undefined> => {
  return axios
    .put(`${BASE_URL}/${order.id}`, order)
    .then((response: AxiosResponse<Response<IOrder>>) => response.data)
    .then((response: Response<IOrder>) => response.data)
}

// external, move later to a resp folder
const STORES_URL = `/website_shops`
const ST_BASE_URL = `${API_URL}${STORES_URL}`

const getStoreByProductUrl = async (url: string): Promise<IStore | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${ST_BASE_URL}/find_by_product_url?productUrl=${url}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

export {getOrders, deleteOrder, addOrder, editOrder, getOrderById, getStoreByProductUrl}
