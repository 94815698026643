import {useCallback, useEffect, useRef, useState} from 'react'
import {SearchCategorySelect} from './SearchCategorySelect'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {selectDebounce} from '../../../../../_metronic/helpers'

import {
  CategoriesQueryResponse,
  ICategorySelectOption,
} from '../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import { IFormProd } from '../../../../pages/orders/_prod-models'

interface Props {
  setFieldValue: ISetFieldValue
  inputName: {category: string; user_name: string; model: string}
  noOptionsMessage?: string
  disabled?: boolean
  selectValues: {
    category: Partial<ICategorySelectOption>
  }
  apiRequest: (cat: string) => Promise<CategoriesQueryResponse>
  values: IFormProd
}

export function CategoriesWrapper({
  setFieldValue,
  inputName,
  noOptionsMessage = 'Не знайдено',
  disabled = false,
  apiRequest,
  selectValues,
  values,
}: Props) {
  const [resultsLoading, setResultsLoading] = useState(false)

  const {category} = selectValues

  const [categoriesResults, setCategoriesResults] = useState<ICategorySelectOption[] | undefined>(
    []
  )

  const filterCategories = useCallback(
    selectDebounce((query: string, callback?: any) => {
      setResultsLoading(true)
      apiRequest(`search=${query}`)
        .then((res) => {
          const reformedCategories = res.data?.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
              deliveryFee: item.deliveryFee || 0,
            }
          })
          // @ts-ignore
          setCategoriesResults(reformedCategories)
          if (callback) {
            callback(reformedCategories)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [apiRequest]
  )

  useEffect(() => {
    filterCategories('')
  }, [filterCategories])

  return (
    <>
      {/* <div className={`fv-row d-flex w-100 flex-column`}> */}
      <SearchCategorySelect
        inputName={inputName.category}
        setFieldValue={setFieldValue}
        value={category}
        options={categoriesResults || []}
        isLoading={resultsLoading}
        onInputChange={filterCategories}
        disabled={disabled}
      />
      {/* </div> */}
    </>
  )
}
