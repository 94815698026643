import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Row} from 'react-table'
import {TOneOfOrders} from '../../../../../../../pages/orders/_models'
import {IProd} from '../../../../../../../pages/orders/_prod-models'
import {orderTypeguards} from '../../../../../../../pages/orders/_typeguards'
import {parcelTypeguards} from '../../../../../../../pages/parcels/core/_typeguards'
import {OrderRowProps} from '../columns/row/Details'

const Products = ({row}: OrderRowProps) => {
  return (
    <table className='table-responsive table-products fw-normal text-custom-grey fs-5'>
      <tbody>
        {orderTypeguards.isIOrderProds(row.original) &&
          row.original.products &&
          row.original.products.map((prod) => <ProdItem prod={prod} key={prod.id} row={row} />)}

        {parcelTypeguards.isIParcelProds(row.original) &&
          row.original.parcelProducts &&
          row.original.parcelProducts.map((prod) => (
            <ProdItem prod={prod} key={prod.id} row={row} />
          ))}
      </tbody>
    </table>
  )
}

export default Products

const ProdItem = ({prod, row}: {prod: IProd; row: Row<TOneOfOrders>}) => {
  const currency = orderTypeguards.isIOrderDirection(row.original)
    ? row.original?.direction?.buyoutCurrency
    : ''

  return (
    <tr key={prod.id} className='h-25px'>
      <td className='p-0 subcell w-25 h-25px'>
        <a href={prod.url} target='_blank' rel='noopener noreferrer' className='text-custom-grey'>
          {' '}
          <OverlayTrigger overlay={<Tooltip id={`prod_${prod.id}`}>{prod.title}</Tooltip>}>
            <span className='me-md-2'>{prod.title}</span>
          </OverlayTrigger>
        </a>
      </td>
      <td className='p-0 subcell h-25px'>
        {prod.quantity} - {prod.priceForAll} {currency}
      </td>
    </tr>
  )
}
