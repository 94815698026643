import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {LayoutSetup, useLayout} from '../../core'
import {Dropdown} from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import {Link} from 'react-router-dom'
import {HeaderUserCreds} from '../../../partials/layout/header-menus/HeaderUserCreds'
import AddButton from '../../../../app/modules/custom/AddButton'

const SidebarHeader = () => {
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  const [minimized, setMinimized] = useState(config?.app?.sidebar?.mode === 'minimized')

  useEffect(() => {
    if (window.innerWidth >= 668 && window.innerWidth < 992) {
      setMinimized(true)
    }
  }, [])

  return (
    <div
      className='app-sidebar-header d-md-flex justify-content-md-center align-items-md-center'
      id='kt_app_sidebar_logo'
    >
      <div className='d-none flex-lg-row d-md-flex justify-content-lg-center align-items-lg-center'>
        {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
          <div
            id='kt_app_sidebar_toggle'
            onClick={() => {
              setMinimized(!minimized)
            }}
            className={clsx(
              'app-sidebar-toggle btn btn-icon btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px rotate',
              {active: appSidebarDefaultMinimizeDefault}
            )}
            data-kt-toggle='true'
            data-kt-toggle-state={toggleState}
            data-kt-toggle-target='body'
            data-kt-toggle-name={`app-sidebar-${toggleType}`}
          >
            <KTSVG
              path='/media/company-specific/pokupka/asideMenuIcon.svg'
              className='svg-icon-2x rotate-180'
              svgClassName='app-sidebar-toggle-icon'
            />
          </div>
        )}

        <AddButton direction={'down'} floating={false} full={!minimized} />
      </div>
      <div className='d-block d-md-none p-5 pb-0'>
        <div className='ms-3'>
          <HeaderUserCreds />
        </div>
        <span className='separator mt-3' />
      </div>
    </div>
  )
}

export default SidebarHeader
