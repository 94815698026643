import {faCreditCard} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {establishHostFromUrl} from '../../../../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {usePageData} from '../../../../../../../../_metronic/layout/core'
import {useListView} from '../../../../../../../pages/orders/buyouts/core/ListViewProvider'
import {useQueryResponse} from '../../../../../../../pages/orders/buyouts/core/QueryResponseProvider'
import {paymentIntents} from '../../../../../../../pages/finances/core/_payment-requests'
import {ID} from '../../../../../../../../_metronic/helpers'

const OrdersListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [loading, setLoading] = useState(false)
  const {setPageCustomData, pageCustomData} = usePageData()

  // const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.BUYOUTS_LIST}-${query}`])
  //     clearSelected()
  //   },
  // })

  const requestOrderPaymentDetails = async () => {
    try {
      setLoading(true)
      const host = establishHostFromUrl()
      const orders = selected
        .filter(
          (order) => !order.data.paid && (!order.data.charges || order.data.charges.length === 0)
        )
        .map((i) => i.id)

      const charges = selected
        .filter((order) => order.data.charges?.length > 0)
        .reduce((acc, order) => [...acc, ...order.data?.charges.map((i: {id: ID}) => i.id)], [])

      const returnUrl = `${
        host === 'localhost' ? 'http://localhost:3011' : `https://my.${host.replace('www.', '')}`
      }/orders/buyouts?session_id={CHECKOUT_SESSION_ID}`

      const res = await paymentIntents.sendOrdersAndChargesPaymentIntent(orders, charges, returnUrl)

      if (res?.clientSecret) {
        setPageCustomData({...pageCustomData, ...res})
      }
    } catch (error) {
      console.log('payment intent error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (pageCustomData?.checkoutShow) {
      requestOrderPaymentDetails()
    }
  }, [pageCustomData?.checkoutShow])

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-normal me-5'>
        <button
          type='button'
          className='d-flex btn btn-clean btn-shadow shadow-pokupka px-4 text-custom-accent fw-bold with-icon'
          onClick={() => setPageCustomData({...pageCustomData, checkoutShow: true})}
        >
          {!loading ? (
            <>
              <FormattedMessage id='ORDERS.PAY' />{' '}
              {`${selected.length}/${
                selected.find((i) => i.data?.direction?.buyoutCurrency)?.data?.direction
                  ?.buyoutCurrency
                // @ts-ignore
              } ${selected.reduce(
                // @ts-ignore

                (acc, i) => acc + +i?.data?.priceToPay,
                0
              )}`}
            </>
          ) : (
            <span className='indicator-progress' style={{display: 'block'}}>
              {/* <FormattedMessage id='GENERAL.BUTTONS.WAIT' /> */}

              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>{' '}
      </div>

      {/* <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button> */}
    </div>
  )
}

export {OrdersListGrouping}
