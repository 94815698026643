import clsx from 'clsx'
import {ButtonGroup} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import HeaderNavigation from '../../../modules/HeaderNavigation'

const TransactionsListToolbar = () => {
  const location = useLocation()
  console.log('loc', location)

  const tab = location.pathname.split('/').at(-1)
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <div>
        <HeaderNavigation />
      </div>
      {/* <ChargesListFilter /> */}
    </div>
  )
}

export {TransactionsListToolbar}
