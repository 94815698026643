import React from 'react'
import {FormattedMessage} from 'react-intl'
import {IParcel} from '../core/_models'

const Header = ({parcel}: {parcel: IParcel}) => {
  return (
    <div className='card-header border-0 justify-content-center align-items-center justify-content-md-between shadow-pokupka p-5 text-custom-additional fs-4'>
      {' '}
      <span className='d-none d-md-block'>
        <FormattedMessage id='PARCEL.PAGE.HEADER.HEADING' />
      </span>{' '}
      <span>
        <span className='fw-bolder me-3'>
          <FormattedMessage id='PARCEL.PAGE.HEADER.CODE' />:
        </span>{' '}
        <span className='bg-custom-container px-4'>{parcel.code}</span>
      </span>
    </div>
  )
}

export default Header
