import {FormattedMessage} from 'react-intl'
import {IOneOfOrders} from '../../../../../../../pages/orders/_models'
import {orderTypeguards} from '../../../../../../../pages/orders/_typeguards'
import {parcelTypeguards} from '../../../../../../../pages/parcels/core/_typeguards'
import {OrderTypesProps} from '../../../OrdersList'

type TProps = {
  pageView?: boolean
}

const Parcel = ({order, orderType, pageView = false}: TProps & IOneOfOrders & OrderTypesProps) => {
  if (orderTypeguards.isIOrderParcel(order) && !order.parcel) {
    return null
  }

  if (
    parcelTypeguards.isIParcelTracks(order) &&
    (!order.trackingNumbers || order.trackingNumbers.length === 0)
  ) {
    return null
  }

  return (
    <div className='fw-normal text-custom-grey'>
      <div
        className={`${
          pageView ? 'd-md-flex flex-md-row align-items-md-center justify-content-md-between' : ''
        }`}
      >
        <span className='fs-3'>
          <FormattedMessage
            id={
              orderType == 'parcel' && pageView
                ? 'PARCEL.PAGE.TRACKS_LABEL'
                : orderType === 'parcel'
                ? 'PARCEL.TRACKS'
                : 'ORDERS.PACKED_NUM'
            }
          />
        </span>

        <div
          className={`d-flex flex-column ${
            pageView ? 'justify-content-center align-items-center mt-5 mt-md-0' : ''
          }`}
        >
          {orderTypeguards.isIOrderParcel(order) && (
            <span className='fs-5 fw-normal text-custom-additional'>
              {order?.parcel?.code || 'GHBGHJ78'}
            </span>
          )}
          {parcelTypeguards.isIParcelTracks(order) &&
            order.trackingNumbers?.map((i) => (
              <span key={i.id} className={`fs-5 fw-normal text-custom-additional `}>
                {i.number}
              </span>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Parcel
