import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTCard, toAbsoluteUrl} from '../../../_metronic/helpers'

const PaymentSuccess = () => {
  return (
    <KTCard>
      {' '}
      <div className='py-20'>
        <div className='mb-10 text-center'>
          <h1 className='text-dark mb-5'>
            <FormattedMessage id='GENERAL.TOOLTIPS.SUCCESSFUL_PAYMENT' />
          </h1>

          <Link to='/' className='btn btn-sm btn-primary mb-3'>
            <FormattedMessage id='GENERAL.BUTTONS.RETURN_HOME' />
          </Link>
        </div>
        <div className='text-center'>
          <img
            src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/17.png')}
            className='mw-100 mh-300px theme-light-show'
            alt=''
          />
        </div>
      </div>
    </KTCard>
  )
}

export default PaymentSuccess
