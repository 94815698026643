import {useFormikContext} from 'formik'
import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {
  IPostShimpentProps,
  postMainShipmentDefault,
} from '../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'

const PostOfficeSelect = ({
  setQuery,
  titles = postMainShipmentDefault,
  filter = '',
}: Partial<IPostShimpentProps>) => {
  const intl = useIntl()
  const {setFieldValue, values} = useFormikContext<any>()
  const [officesLoading, setOfficesLoading] = useState(false)
  const [officesResults, setOfficesResults] = useState<IBasicSelect[]>([])

  const [mainOfficeSearchQuery, setMainOfficeSearchQuery] = useState('')

  useEffect(() => {
    const filterOfficeNumbers = async () => {
      setOfficesLoading(true)
      await locationsApi
        .getNovaPoshtaOffices(mainOfficeSearchQuery, values.mainOfficeCity || '', false)
        .then((res) => {
          setOfficesResults(res)
        })
        .catch((err) => console.log(err))
        .finally(() => setOfficesLoading(false))
    }
    filterOfficeNumbers()
  }, [values.mainOfficeCity, mainOfficeSearchQuery])

  return (
    <>
      {/* {withHeadTitles === true && ( */}
      <label className='fs-6'>
        <FormattedMessage id={titles.title} />
      </label>
      {/* )} */}

      <div className='d-flex justify-content-around flex-column'>
        <InputTemplate
          inputName='officeNumber'
          required={true}
          type='select'
          title={intl.formatMessage({id: titles.officeTitle})}
          value={values.mainOfficeNumber}
          reactSelectprops={{
            options: officesResults,
            isLoading: officesLoading,
            onInputChange: setQuery ? setQuery : () => {},
            isClearable: true,
            selectName: 'officeNumber',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: titles.choosePlaceholder}),
            currentValue: officesResults.find(
              (item: any) => item.value === values.mainOfficeNumber
            ),
          }}
        />
      </div>
    </>
  )
}

export default PostOfficeSelect
