import {IParcel} from './_models'

function isIParcelProds(parcel: any): parcel is IParcel {
  return (parcel as IParcel).parcelProducts !== undefined
}

function isIParcelCode(parcel: any): parcel is IParcel {
  return (parcel as IParcel).code !== undefined
}

function isIParcelTracks(parcel: any): parcel is IParcel {
  return (parcel as IParcel).trackingNumbers !== undefined
}

function isIParcelTtn(parcel: any): parcel is IParcel {
  return (parcel as IParcel).ttn !== undefined
}

export const parcelTypeguards = {
  isIParcelProds,
  isIParcelTracks,
  isIParcelTtn,
  isIParcelCode,
}
