import * as Yup from 'yup'
import {
  houseLetterSubSchema,
  houseNumberSubSchema,
} from '../../../../../_metronic/helpers/custom/yup/partials/addressesPartials'

export const receiverTypeSchema = (intl: any) =>
  Yup.array()
    .min(1, intl.messages.YUP_VALIDATION_RECEIVER_ENTER_A_REC_TYPE)
    .required(intl.messages.YUP_VALIDATION_RECEIVER_ENTER_A_REC_TYPE)
    .label(intl.messages.YUP_VALIDATION_RECEIVER_REC_TYPE)

export const recTypeOfDeliverySchema = (intl: any) =>
  Yup.number().when('receiverType', (receiverType) => {
    return receiverType.includes(2)
      ? Yup.number()
          .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_DELIVERY_TYPE)
          .label(intl.messages.YUP_VALIDATION_SHIPMENT_DELIVERY_TYPE)
      : Yup.number().label(intl.messages.YUP_VALIDATION_SHIPMENT_DELIVERY_TYPE)
  })

export const physicalAddressHouseNumberSchema = (intl: any) =>
  Yup.string()
    .typeError(intl.messages.YUP_VALIDATION_GENERAL_ONLY_NUMBERS)
    .when('receiverType', (receiverType) => {
      return receiverType.includes(1) ? houseNumberSubSchema(intl) : Yup.number()
    })

export const physicalAddressHouseLetterSchema = (intl: any) =>
  Yup.string().when('receiverType', (receiverType) => {
    return receiverType.includes(1) ? houseLetterSubSchema(intl) : Yup.string()
  })

export const emailSchema = Yup.string()

export const passportSchema = Yup.mixed()
