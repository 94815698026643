import {useFormikContext} from 'formik'
import {AddressShipment} from '../../../../modules/custom/sections/shipment/_modals/address-shipment/AddressShipment'
import {PostShipment} from '../../../../modules/custom/sections/shipment/_modals/post-shipment/PostShipment'
import {NewReceiverFullForm} from '../../core/_models'
import AptOrOffice from '../modules/AptOrOffice'
import HouseAndLetter from '../modules/HouseAndLetter'
import PostOfficeSelect from '../modules/PostOfficeSelect'
import StreetSelect from '../modules/StreetSelect'

const ShipmentDetails = ({values}: {values: Partial<NewReceiverFullForm>}) => {
  const {setFieldValue} = useFormikContext()

  return (
    <>
      {values.typeOfDelivery && +values.typeOfDelivery === 1 && (
        <div className='col d-md-flex flex-gutter'>
          {' '}
          <StreetSelect />
          <HouseAndLetter />
          <AptOrOffice />
        </div>
      )}
      {values.typeOfDelivery && +values.typeOfDelivery === 2 && (
        <div className='w-100 w-md-50'>
          <PostOfficeSelect />
        </div>
      )}
    </>
  )
}

export default ShipmentDetails
