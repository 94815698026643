/* eslint-disable jsx-a11y/anchor-is-valid */
import {IOrderProps} from '../../../../../../../../pages/orders/_models'
import Actions from '../../modules/Actions'
import DetailedButton from '../../modules/DetailedButton'

const ActionsCell = ({order}: IOrderProps) => {
  return (
    <>
      <div className='d-none d-md-none d-lg-flex justify-content-center flex-shrink-0'>
        <Actions order={order} />
      </div>
      <DetailedButton
        order={order}
        addClass='d-none d-md-block d-lg-none text-decoration-underline'
      />
    </>
  )
}

export {ActionsCell}
