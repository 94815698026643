import {useQuery, useQueryClient} from 'react-query'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from '../../../_metronic/helpers/custom/api/directionsApi'
import {locationsApi} from '../../../_metronic/helpers/custom/api/locationsApi'
import {ChargesListWrapper} from './charges-list/ChargesList'
import {TransactionsListWrapper} from './transactions-list/TransactionsList'

const FinancesPage = () => {
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  useQuery(QUERIES.DIRECTIONS_LIST, () => directionsApi.fetchDirectionsWithIds(countries))

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path={`charges`} element={<ChargesListWrapper />} />
        <Route path={`transactions`} element={<TransactionsListWrapper />} />
      </Route>
      <Route index element={<Navigate to='/finances/charges' />} />
    </Routes>
  )
}

export default FinancesPage
