import {useFormikContext} from 'formik'
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import AsyncSelect from 'react-select/async'

import {components} from 'react-select'
import {
  getSavedFilter,
  initialQueryState,
  QUERIES,
  selectDebounce,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../_metronic/helpers'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {useQueryResponsePagination} from '../../../core/QueryResponseProvider'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import {directionsApi} from '../../../../../../_metronic/helpers/custom/api/directionsApi'
import {IBasicSelect} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {directionsFuncs} from '../../../../../../_metronic/helpers/custom/funcs/directions'
import {FlagsDirectionBar} from '../../../../../modules/custom/FlagsDirectionBar'
import {customStyles} from '../../../../../modules/custom/form-elements/select/ReactSelectTemplate'
import {Direction} from '../../../../../../_metronic/helpers/custom/tsHelpers/directionsHelpers'

const ChargesListFilter = () => {
  const pagination = useQueryResponsePagination()
  const {updateState, state} = useQueryRequest()

  const [savedFilter, setSavedFilter] = useState<Record<string, any>>({})

  useEffect(() => {
    MenuComponent.reinitialization()
    const savedFilter = getSavedFilter(QUERIES.CHARGES_LIST)
    if (savedFilter) {
      setSavedFilter(savedFilter)
    }
  }, [])
  useEffect(() => {
    filterData()
  }, [savedFilter?.direction])

  const resetFilter = () => {
    updateState({
      filter: undefined,
      ...initialQueryState,
      itemsPerPage: pagination.itemsPerPage || 10,
    })
    setSavedFilter({})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.CHARGES_LIST), '{}')

    const allMenus = document.querySelectorAll('.menu.show')
    const allMenusArray = Array.from(allMenus)
    allMenusArray.map((menu) => menu.classList.remove('show'))
  }

  const filterData = () => {
    const newFilters: {[key: string]: any} = {
      direction: savedFilter?.direction?.value,
    }

    updateState({
      ...state,
      filter: {...state.filter, ...newFilters},
    })
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.CHARGES_LIST),
      JSON.stringify(newFilters)
    )
  }

  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  // const directions: Direction[] = queryClient.getQueryData([QUERIES.DIRECTIONS_LIST]) || []

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterDirections = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      directionsApi
        .fetchDirectionsWithIds(countries)
        .then((res) => {
          if (res) {
            const reformed = res?.map((item) => {
              return {
                ...item,
                label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries),
                value: item.id,
              }
            })
            setResults(reformed)
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [directionsApi.fetchDirectionsWithIds]
  )

  useEffect(() => {
    filterDirections()
  }, [])

  useEffect(() => {
    filterDirections()
  }, [countries])

  const optionFormat = (v: IBasicSelect & Partial<Direction>) => (
    <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
      <FlagsDirectionBar chosenDirections={[{id: v.value}]} minified={true} />

      <div className='d-flex flex-column fw-bold text-gray-800 '>
        {' '}
        {v?.countryFrom &&
          v?.countryTo &&
          directionsFuncs.formDirection(v?.countryFrom, v?.countryTo, countries)}
      </div>
    </div>
  )
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Не знайдено</span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      callback(results)
      return
    }

    filterDirections()
  }

  // const formik = useFormikContext<any>()

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    setSavedFilter((prev) => {
      return {...prev, direction: selectedValue}
    })
    // if (onChange) {
    //   onChange(selectedValue)
    //   return
    // } else if (formik && selectedValue !== null) {
    //   formik?.setFieldValue('user', selectedValue)
    // } else {
    //   formik?.setFieldValue('user', '')
    // }
  }

  return (
    <div
      className={`fv-row d-flex mt-1
      flex-column mb-4`}
    >
      {countries && (
        <AsyncSelect
          styles={{
            ...customStyles,
            control: (provided) => {
              return {...provided, minHeight: '40px', height: '40px', borderRadius: '0'}
            },
          }}
          formatOptionLabel={optionFormat}
          className='form-control form-control-lg border-0 p-0 fw-normal fs-6'
          loadOptions={loadOptions}
          defaultOptions={results}
          form={'direction'}
          isDisabled={false}
          components={{NoOptionsMessage}}
          loadingMessage={() => 'Зачекайте...'}
          placeholder={'Напрямок'}
          isClearable={true}
          isLoading={resultsLoading}
          value={savedFilter?.direction?.value ? savedFilter?.direction : null}
          onChange={handleOnChange}
          onInputChange={(e) => {
            filterDirections(e)
          }}
        />
      )}
    </div>
  )
}

export default ChargesListFilter
