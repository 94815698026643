import {ErrorMessage, useFormikContext} from 'formik'
import React, {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ICountry} from '../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import CitiesSelect from '../../../../modules/custom/form-elements/select/CitiesSelect'
import {CountriesSelect} from '../../../../modules/custom/form-elements/select/CountriesSelect'
import {NewReceiverFullForm} from '../../core/_models'
import DelTypeRadios from './DelTypeRadios'
import {ReceiverType} from './ReceiverType'

const Country = ({values}: {values: NewReceiverFullForm}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const countriesAbbr: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countriesResults, setCountriesResults] = useState<(IBasicSelect & Partial<ICountry>)[]>([])

  const filterCountries = async () => {
    setCountriesLoading(true)
    await locationsApi
      .getAvailableCountries('filter[receive]=true')
      .then((res) => {
        if (res?.data)
          setCountriesResults(
            res?.data?.map((i) => ({...i, value: i.id, label: countriesAbbr[i.country]}))
          )
      })
      .catch((err) => console.log(err))
      .finally(() => setCountriesLoading(false))
  }

  useEffect(() => {
    filterCountries()
  }, [])

  return (
    <>
      {countriesResults && countriesResults?.length > 1 && (
        <div>
          <label className='required fs-6'>
            <FormattedMessage id='ORDERS.COUNTRY' />
          </label>
          <CountriesSelect
            setCountriesLoading={setCountriesLoading}
            setCountriesResults={setCountriesResults}
            options={countriesResults}
            isLoading={countriesLoading}
            onInputChange={filterCountries}
            queryFilter='filter[receive]=true'
            addContClass=''
          />
        </div>
      )}
    </>
  )
}

export default Country
