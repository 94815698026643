import {faCreditCard} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useFormikContext} from 'formik'
import {Toast} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {TFooterNotification} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {usePageData} from '../../../../_metronic/layout/core'
import {Notification} from '../Notification'

interface Props {
  loading?: boolean
  buttonText?: string
  sendRequest?: (bool?: any) => any
  handleApi?: any
  apiData?: any
  disabled?: boolean
  success: boolean
  notification?: TFooterNotification
  addClass?: string
  staticPosition?: boolean
  toast?: boolean
  addButton?: boolean
  addButtonSvg?: string
  buttonClass?: string
}
export function CardFooter({
  loading,
  disabled = false,
  buttonText,
  sendRequest,
  handleApi,
  apiData,
  success,
  notification,
  addClass,
  staticPosition = false,
  toast = true,
  addButton = false,
  addButtonSvg = '/media/company-specific/pokupka/cart.svg',
  buttonClass = '',
}: Props) {
  const intl = useIntl()
  const {setPageCustomData, pageCustomData} = usePageData()
  const buttonTextFinal = buttonText ? buttonText : intl.formatMessage({id: 'GENERAL.BUTTONS.SAVE'})
  const disabledSubmit = loading === true || disabled === true ? true : false
  const performRequest = (e?: React.MouseEvent<HTMLElement>, preventDefault?: boolean) => {
    try {
      if (preventDefault === true && e) {
        e.preventDefault()
      }
      if (sendRequest) {
        sendRequest(true)
      } else if (handleApi) {
        handleApi(apiData)
      } else {
        console.log('empty click')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const formik = useFormikContext<any>()

  const submBtnClass = `${
    buttonClass
      ? buttonClass
      : `rounded-circle btn text-white fw-normal fw-bold px-5 ms-2 btn-custom-text d-flex justify-content-center align-items-center`
  } btn-normal`
  return (
    <div
      className={`card-footer d-flex align-items-center bg-white border-0 ${
        staticPosition ? 'position-relative' : 'position-absolute'
      } ${addClass ? addClass : 'justify-content-center pt-4 px-0 pb-10 pb-md-0'}`}
    >
      {toast && (
        <Toast
          show={success}
          // onClose={() => handleClose(data.id)}
          delay={3000}
          autohide
          style={{zIndex: '0'}}
          className={`bg-light-${
            notification?.color
          } text-gray-800 w-100 h-100 top-0 start-0 border-0 ${
            staticPosition ? 'position-relative' : 'position-absolute'
          }`}
        >
          <Toast.Body className={`bg-light-${notification?.color}`}>
            <Notification
              width='w-50'
              // noteText={intl.formatMessage({id: 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING'})}
              // color={'success'}
              // icon='/media/icons/duotune/general/gen043.svg'
              noteText={intl.formatMessage(
                {
                  id: notification?.intl?.id || 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING',
                },
                notification?.intl.values
              )}
              color={notification?.color || 'success'}
              icon={notification?.icon || '/media/icons/duotune/general/gen043.svg'}
            />
            {/* <p className='m-0 p-0'></p> */}
          </Toast.Body>
        </Toast>
      )}

      <div
        className={`button-container d-flex flex-row flex-gutter align-items-center justify-content-center ${
          staticPosition ? '' : 'position-absolute'
        }`}
      >
        <button
          type='submit'
          className={submBtnClass}
          disabled={disabledSubmit}
          style={{zIndex: '100'}}
          onClick={(e) => (handleApi ? performRequest(e, true) : performRequest())}
        >
          {!loading ? (
            addButton ? (
              <>
                {' '}
                <KTSVG
                  path={addButtonSvg}
                  className='svg-icon-4 icon svg-icon-custom-white d-flex align-items-center justify-content-center'
                  svgClassName='svg'
                />
                <span className='px-4'>{buttonText}</span>
              </>
            ) : (
              buttonTextFinal
            )
          ) : (
            <span className='indicator-progress' style={{display: 'block'}}>
              {/* <FormattedMessage id='GENERAL.BUTTONS.WAIT' /> */}

              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {pageCustomData?.checkoutButton && (
          <button
            type='submit'
            className={`${submBtnClass} px-3`}
            disabled={disabledSubmit}
            style={{zIndex: '100'}}
            onClick={() => {
              const newData = {...pageCustomData, checkoutShow: true}

              // if (formik?.values?.direction) {
              //   newData.direction = formik?.values?.direction
              // }
              setPageCustomData(newData)
            }}
          >
            {!loading ? (
              <>
                <FontAwesomeIcon icon={faCreditCard} />
                <span className='px-4'>
                  <FormattedMessage id='ORDERS.PAY' />
                </span>
              </>
            ) : (
              <span className='indicator-progress' style={{display: 'block'}}>
                {/* <FormattedMessage id='GENERAL.BUTTONS.WAIT' /> */}

                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  )
}
