import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {DateCell} from './cells/DateCell'
import {CountryCell} from './cells/CountryCell'
import {NumberCell} from './cells/NumberCell'
import {LogoCell} from './cells/LogoCell'
import {WeightCell} from './cells/WeightCell'
import {CostCell} from './cells/CostCell'
import {StatusCell} from './cells/StatusCell'
import {ActionsCell} from './cells/ActionsCell'
import {TOneOfOrders} from '../../../../../../../pages/orders/_models'
import TtnCell from './cells/TtnCell'
import {SelectionHeader} from './SelectionHeader'
import {SelectionCell} from './cells/SelectionCell'
import {ICharge} from '../../../../../../../pages/finances/core/_models'

const tableColumns: ReadonlyArray<Column<TOneOfOrders>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} className='d-none selection-th' />,
    id: 'selection',
    Cell: ({...props}) => {
      const showCell =
        props.data[props.row.index].type === 'buyout' &&
        (props.data[props.row.index].charges.some((i: ICharge) => !i.paid && !!i.allowPay) ||
          !props.data[props.row.index].paid)

      return showCell ? <SelectionCell order={props.data[props.row.index]} /> : <></>
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'ORDERS.DATE'} className='min-w-50px mw-75px' />
    ),
    id: 'date',
    Cell: ({...props}) => <DateCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'ORDERS.COUNTRY'}
        className='min-w-50px max-w-100px d-none d-md-table-cell d-lg-table-cell'
      />
    ),
    id: 'country',
    Cell: ({...props}) => <CountryCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'ORDERS.NUM'} className='min-w-100px max-w-150px' />
    ),
    id: 'number',
    Cell: ({...props}) => <NumberCell order={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'ORDERS.LOGO'}
        className='max-w-75px d-none logo-th'
      />
    ),
    id: 'logo',
    Cell: ({...props}) => <LogoCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={
          window.matchMedia('(max-width: 991px)').matches ? 'ORDERS.WEIGHT_COST' : 'ORDERS.WEIGHT'
        }
        className='max-w-50px d-none d-md-table-cell'
      />
    ),
    id: 'weight',
    Cell: ({...props}) => <WeightCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'ORDERS.COST'}
        className='min-w-100px d-none d-md-none d-lg-table-cell'
      />
    ),
    id: 'cost',
    Cell: ({...props}) => <CostCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'PARCEL.TTN'} className='min-w-100px parcel-ttn' />
    ),
    id: 'ttn',
    Cell: ({...props}) => <TtnCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'ORDERS.STATUS'}
        className='min-w-100px d-none d-md-none d-lg-table-cell'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <StatusCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        // title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-end d-none d-md-table-cell'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell order={props.data[props.row.index]} />,
  },
]

export {tableColumns}
