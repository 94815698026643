import React from 'react'
import {useIntl} from 'react-intl'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'

const Name = ({values}: {values: any}) => {
  const intl = useIntl()
  return (
    <>
      <InputTemplate
        inputName='lastName'
        type='text'
        required={true}
        fieldWidth={'w-100 w-md-50 w-lg-25'}
        title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.LAST_NAME'})}
        // addSymbol={
        //   <KTSVG
        //     path='/media/icons/duotune/communication/com006.svg'
        //     className='svg-icon-3'
        //   />
        // }
      />
      <InputTemplate
        inputName='firstName'
        type='text'
        required={true}
        fieldWidth={'w-100 w-md-50 w-lg-25'}
        title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NAME'})}
        // addSymbol={
        //   <KTSVG
        //     path='/media/icons/duotune/communication/com006.svg'
        //     className='svg-icon-3'
        //   />
        // }
      />
      <InputTemplate
        inputName='patronymic'
        type='text'
        fieldWidth={'w-100 w-md-50 w-lg-25'}
        title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PATRONYMIC'})}
        required={!values.noPatronymic}
        // addSymbol={
        //   <KTSVG
        //     path='/media/icons/duotune/communication/com006.svg'
        //     className='svg-icon-3'
        //   />
        // }
      />
    </>
  )
}

export default Name
