import * as Yup from 'yup'
import {
  apartmentSchema,
  citySchema,
  houseLetterSchema,
  houseNumberSchema,
  mainOfficeCitySchema,
  mainOfficeNumberSchema,
  officeSchema,
  premisesTypeSchema,
  reserveOfficeCitySchema,
  reserveOfficeNumberSchema,
  streetSchema,
} from '../../../../../_metronic/helpers/custom/yup/partials/addressesPartials'
import {
  basicNameSchema,
  patronymicOptionalSchema,
  phoneNumberSchema,
} from '../../../../../_metronic/helpers/custom/yup/partials/generalPartials'
import {emailSchema, passportSchema, receiverTypeSchema, recTypeOfDeliverySchema} from './partials'

const receiverOverviewSchema = (intl: any) =>
  Yup.object().shape({
    active: Yup.number().required(intl.messages.YUP_VALIDATION_RECEIVER_PICK_STATUS),
    firstName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME),
    lastName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME),
    patronymic: patronymicOptionalSchema(intl),
    passport: passportSchema,
    receiverType: receiverTypeSchema(intl),
  })

const receiverContactsSchema = (intl: any) =>
  Yup.object().shape({
    phoneNumber: phoneNumberSchema(intl),
    email: emailSchema,
  })

const addReceiverSchema = (intl: any) =>
  Yup.object().shape({
    firstName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME),
    lastName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME),
    patronymic: patronymicOptionalSchema(intl),
    // document: passportSchema(intl),
    receiverType: receiverTypeSchema(intl),
    phoneNumber: phoneNumberSchema(intl),
    // add physicalAddressCity
    // physicalAddressStreet
    // physicalAddressPremisesType
    // physicalAddressApartment
    // physicalAddressOffice
    // email: emailSchema,
    // typeOfDelivery: recTypeOfDeliverySchema(intl),
    // houseNumber: houseNumberSchema(intl),
    // houseLetter: houseLetterSchema(intl),
    // physicalAddressHouseNumber: physicalAddressHouseNumberSchema(intl),
    // physicalAddressHouseLetter: physicalAddressHouseNumberSchema(intl),
    // street: streetSchema(intl),
    // premisesType: premisesTypeSchema(intl),
    // apartment: apartmentSchema(intl),
    // office: officeSchema(intl),
    // city: citySchema(intl),
    // mainOfficeCity: mainOfficeCitySchema(intl),
    // mainOfficeNumber: mainOfficeNumberSchema(intl),
    // reserveOfficeCity: reserveOfficeCitySchema(intl),
    // reserveOfficeNumber: reserveOfficeNumberSchema(intl),
  })

export {receiverOverviewSchema, receiverContactsSchema, addReceiverSchema}
