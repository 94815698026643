import clsx from 'clsx'
import {Row} from 'react-table'
import {orderTypeColors} from '../../../../../goods/GoodsItemBody'
import {OrderTypes, OrderTypesProps} from '../../../../OrdersList'
import {LogoCell} from '../cells/LogoCell'
import {
  Actions,
  DetailedButton,
  Invoice,
  Logs,
  StatusAndTtn,
  Parcel,
  Products,
  Statuses,
} from '../../modules'
import {TOneOfOrders} from '../../../../../../../../pages/orders/_models'
import {orderTypeguards} from '../../../../../../../../pages/orders/_typeguards'
import {FormattedMessage} from 'react-intl'
import {parcelTypeguards} from '../../../../../../../../pages/parcels/core/_typeguards'

export type OrderRowProps = {
  row: Row<TOneOfOrders>
  orderType?: OrderTypes
  open?: boolean
}
const Details = ({row, orderType, open}: OrderRowProps & OrderTypesProps) => {
  return (
    <tr className={clsx('details')}>
      <td
        colSpan={orderType === 'buyout' ? 9 : 8}
        className={clsx(
          `border border-color-${
            orderType ? orderTypeColors[orderType] : ''
          } border-left-5 border-radius-left-def border-radius-right-def`,
          {'border-top-0 pt-0': open},
          {'border-radius-left-top-unset border-radius-right-top-unset': open}
        )}
      >
        <span className='separator d-none d-md-block' />
        <div className='row flex-column flex-md-row justify-content-md-around flex-lg-nowrap pt-0 py-md-6 px-3'>
          {orderTypeguards.isIOrderType(row.original) && row.original.type === 'buyout' && (
            <div className='col-1 order-md-0 d-none d-md-flex d-lg-none align-items-center'>
              <LogoCell order={row.original} />
            </div>
          )}
          <div className='col-12 col-md-6 order-0 order-md-1 d-flex d-lg-none align-items-center'>
            <StatusAndTtn row={row} />
          </div>
          <span className='separator d-none d-md-block d-lg-none order-md-3 my-4 border-color-none' />
          <div
            className={clsx(
              'col col-md-3 col-lg-2 order-1 order-md-4 text-center py-2 p-md-0 pe-lg-5',
              {
                'd-none':
                  orderTypeguards.isIOrderType(row.original) && row.original.type === 'buyout',
              }
            )}
          >
            <Invoice order={row.original} />
          </div>{' '}
          <div className='col col-lg-3 order-3 order-md-5 d-flex flex-column justify-content-between bt-xs py-6 py-md-0 px-md-6 px-lg-5'>
            {orderType !== 'parcel' && <Products row={row} />}
            <div className='d-none d-md-flex'>
              <Parcel order={row.original} orderType={orderType} />
              {parcelTypeguards.isIParcelTtn(row.original) && (
                <div className='fw-normal'>
                  <FormattedMessage id={'ORDERS.WAYBILL'} />

                  <span className='fs-5 text-custom-additional'>{row.original?.ttn}</span>
                </div>
              )}
            </div>{' '}
          </div>{' '}
          <div className='col d-md-none order-4 d-flex flex-column justify-content-between bt-xs px-0 py-6 py-md-0 px-md-6'>
            {' '}
            <Parcel order={row.original} orderType={orderType} />
          </div>
          <div
            className={`col col-md-5 order-5 order-md-6 bt-xs py-6 py-md-0 ms-md-4 ms-lg-0 pe-8 ${
              orderType === 'buyout' ? 'pe-md-10' : 'ps-md-10'
            } `}
          >
            <div className='mb-3'>
              <Statuses order={row.original} />
            </div>
            <div>
              <Logs order={row.original} />
            </div>
          </div>
          <div className='col-12 col-md-4 order-5 order-md-2 d-flex d-lg-none justify-content-center bt-xs py-2 px-0 py-md-0'>
            <Actions order={row.original} />
          </div>
          <div className='d-flex justify-content-center d-md-block bt-xs col col-md-3 col-lg-2 order-last order-md-6 order-lg-5 pt-7 pb-md-0 px-md-6 ps-lg-4'>
            <DetailedButton order={row.original} addClass='btn-shadow shadow-pokupka' />
          </div>
        </div>
        {/* ) : (
              <div className='d-flex flex-fill justify-content-center my-3'>
                <Spinner color='warning' /> 
              </div>
            )} */}
      </td>
    </tr>
  )
}

export default Details
