/* eslint-disable jsx-a11y/anchor-is-valid */
import {IOrderProps} from '../../../../../../../../pages/orders/_models'
import {Country} from '../../modules'
const CountryCell = ({order}: IOrderProps) => {
  const {} = order

  return (
    <div className='text-center min-w-50px max-w-100px'>
      <Country order={order} />
    </div>
  )
}

export {CountryCell}
