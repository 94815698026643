import {Form, Formik} from 'formik'
import {Dispatch, SetStateAction, useState} from 'react'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../_metronic/helpers'
import {
  ICrauzerProfileForm,
  ICrauzerUser,
} from '../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {
  ISetFieldValue,
  TFooterNotification,
} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {CardFooter} from '../../../modules/custom/form-elements/CardFooter'
import {crauzerProfileApi} from './core/_requests'
import ProfileAddress from './components/ProfileAddress'
import ProfileBasics from './components/ProfileBasics'
import ProfileHeader from './components/ProfileHeader'

interface TProps {
  user: ICrauzerUser
  setCurrentUser: Dispatch<SetStateAction<ICrauzerUser | undefined>>
}
const PageForm = ({user, setCurrentUser}: TProps) => {
  const initialValues: Partial<ICrauzerProfileForm> = {
    ...Object.fromEntries(Object.entries(user).filter(([_, v]) => v !== null)),
    contactPhoneNumbers:
      user?.contactPhoneNumbers && user?.contactPhoneNumbers.length > 0
        ? [...user?.contactPhoneNumbers]
        : [''],
    city: {value: '', label: ''},
    deliveryType: 1,
    password: '',
    passwordConf: '',
  }

  const [loading, setLoading] = useState(false)
  const [imageIsLoading, setImageIsLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<TFooterNotification>(null)

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setLoading(true)
    setImageIsLoading(true)

    // await handleFileUpload(e, setFieldValue, 'passport', 'file', crauzerProfileApi.addPassportScan, true)

    setImageIsLoading(false)
    setLoading(false)
  }

  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    setFieldValue('passport', '')
  }

  const handleSubmit = async (values: Partial<ICrauzerProfileForm>) => {
    console.log('values', values)
    const {firstName, lastName, email, phoneNumber, patronymic, contactPhoneNumbers} = values
    try {
      setLoading(true)
      const response = await crauzerProfileApi.editProfile({
        firstName,
        lastName,
        email,
        phoneNumber,
        patronymic,
        contactPhoneNumbers: contactPhoneNumbers?.filter((i) => !!i),
      })
      console.log('resp', response)

      // setCurrentUser({
      //   ...user,
      //   firstName: response.firstName,
      //   lastName: response.lastName,
      //   email: response.email,
      //   phoneNumber: response.phoneNumber,
      // })
      setFooterNotification({
        ...CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED,
        intl: {id: 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING'},
      })
      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className='card profile-form h-md-100'>
      <Formik
        // validationSchema={profileOverviewSchema(intl)}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form h-md-100'>
            {/* {JSON.stringify(initialValues)} */}
            {/* <ProfileHeader
              imageIsLoading={imageIsLoading}
              setImageIsLoading={setImageIsLoading}
              saveImage={saveImage}
              deleteImage={deleteImage}
              values={values}
              displayClass='d-md-none'
            /> */}
            <div className='card-body d-block d-md-flex flex-md-row pe-md-0 h-md-100'>
              <div className='d-none d-md-block col-md-6 col-lg-4 shadow-pokupka'>
                {' '}
                {/* <ProfileHeader
                  imageIsLoading={imageIsLoading}
                  setImageIsLoading={setImageIsLoading}
                  saveImage={saveImage}
                  deleteImage={deleteImage}
                  values={values}
                /> */}
                <ProfileBasics
                  imageIsLoading={imageIsLoading}
                  saveImage={saveImage}
                  deleteImage={deleteImage}
                />
              </div>
              <ProfileBasics
                displayClass='d-md-none'
                imageIsLoading={imageIsLoading}
                saveImage={saveImage}
                deleteImage={deleteImage}
              />

              <ProfileAddress
                loading={loading}
                success={footerNotification?.success || false}
                imageIsLoading={imageIsLoading}
                saveImage={saveImage}
                deleteImage={deleteImage}
                footerNotification={footerNotification}
              />
            </div>
            <div className='d-md-none'>
              <CardFooter
                loading={loading}
                success={footerNotification?.success || false}
                notification={footerNotification}
                addClass={'justify-content-end'}
                staticPosition={true}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PageForm
