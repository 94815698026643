import axios from 'axios'
import {ID} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_PATH
const PAYMENTS_URL = `/payments`
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${PAYMENTS_URL}`

const sendOrdersAndChargesPaymentIntent = async (
  orders: ID[],
  charges: ID[],
  returnUrl: string
) => {
  return axios
    .post(`${BASE_URL}/orders`, {orders, charges, returnUrl})
    .then((response) => response.data)
}

const checkPaymentStatus = async (sessionId: string) => {
  return axios.get(`${BASE_URL}/session_status?session_id=${sessionId}`)
}

export const paymentIntents = {
  sendOrdersAndChargesPaymentIntent,
  checkPaymentStatus,
}
